<template>
  <div>
    <div id="broadcast"><img src="../assets/banner.png" alt="MARK 200"> </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      myChars: ""
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
* {
  font-family: "fangzheng";
  text-align: center;
  transition: all .3s;
  font-size: 40px;
}

#broadcast {
  /* margin-top: 50px; */
  border-color: rgb(194, 191, 254);
  border-style: solid;
  margin: auto;
}

#broadcast img {
  width: 100%;

}
</style>