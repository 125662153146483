import { createStore } from 'vuex'

export default createStore({
  state() {
    return {
      answerList: {},
      actualAnswer: {},
      errorDetail: {}
    };
  },
  mutations: {
    setAnswerList: (state, data) => {
      state.answerList = data;
    },
    setActualAnswer: (state, data) => {
      state.actualAnswer = data;
    },
    setErrorDetail: (state, data) => {
      state.errorDetail = data
    }
  },
  actions: {},
  modules: {},
});
