<template>
  <div id="honeycomb">
    <div class="body">
      <div class="back">
        <el-button type="primary" class="back-button" round @click="quit"> 返回</el-button>
      </div>
      <div class="circle">

        <div class="wrapper">

          <audio id="audio" controls="controls" :src="cusSrc"></audio>

          <div class="photo">
            <canvas ref="canvas" height="350" width="350">

            </canvas>

          </div>
        </div>

      </div>
      <div class="logo_list">

        <div class="logo_wrapper">
          <div> 😮 </div>
        </div class="logo_wrapper">


        <div class="logo_wrapper">
          <div>😀 </div>
        </div>


        <div class="logo_wrapper">
          <div>😍 </div>
        </div>


        <div class="logo_wrapper">
          <div>🤤 </div>
        </div>

        <div class="logo_wrapper">
          <div>🤤 </div>
        </div>


        <div class="logo_wrapper">
          <div>😋</div>
        </div>

        <div class="logo_wrapper">
          <div>🤓 </div>
        </div>


        <div class="logo_wrapper">
          <div>😚</div>
        </div>

      </div>
    </div>
    <div class="slide">

      <div class="fistbump">
        <div class="fist" ref="leftFist">🤜</div>
        <div class="fist" ref="rightFist">🤛</div>
      </div>

      <div class="challenge">
        <el-button @click="goBtn" ref="challengeBtn" style="color: aliceblue;"> <strong>Go</strong> </el-button>
      </div>
    </div>
    <div class="footer">
      <!-- <div style="width: 30%;"></div> -->
      <h2>
        <div class="mask">
          <span data-show>数一数雾里雾都有几个八</span>
          <span>正在兑换苏坡扣马币</span>
          <span>恋丑癖防治手册正在发放中</span>
          <span>闪开！本网站震碎听力圈！</span>
          <span>听说了吗？新疆葡萄大！</span>
          <span>窝不直捣这算什么听力</span>
          <span>在Prada蹲守我担给我买单中</span>
          <span>学英语，易如反掌！</span>
          <span>加油抠脚兵，快做听力</span>
          <span>请不要对着本听力傻笑</span>
        </div>
      </h2>

    </div>
  </div>


</template>

<script>

import { test } from "../../api/index";


export default {

  data() {
    return {
      sceneImg: '',
      ifEnd: false,
      request: {},
      cusSrc: ''
    }

  },
  created() {
    this.request.paperId = this.$route.query.paperId;
    this.request.idolId = this.$route.query.idolId;
    this.request.cusType = 0
    test.getChallengeVoice(this.request)
      .then((res) => {
        if (res.code == 200) {
          this.cusSrc = res.data.cusSrc
          this.sceneImg = res.data.sceneImg
        }
      })
  },


  mounted() {


    // 下边框文字切换

    this.timer = setInterval(function () {

      const show = document.querySelector('span[data-show]')

      const next = show.nextElementSibling || document.querySelector('.mask span:first-child')

      const up = document.querySelector('.mask span[data-up]')

      if (up) {
        up.removeAttribute('data-up')
      }

      show.removeAttribute('data-show')
      show.setAttribute('data-up', '')

      next.setAttribute('data-show', '')
    }, 3000)

  },

  methods: {
    quit() {
      const vm = this;
      vm.$router.push({ name: "kieltsView" });
    },
    goBtn() {

      this.$refs.rightFist.classList.add('right_fist')
      this.$refs.leftFist.classList.add('left_fist')
      setTimeout(() => {
        this.$router.push({ name: 'challengeExam', query: { paperId: this.request.paperId, idolId: this.request.idolId } })
      }, 2500);
    }
  },
  beforeUnmount() {
    clearInterval(this.timer)
  },
  watch: {
    cusSrc(newVal, oldVal) {
      console.log(newVal)
      if (newVal) {
        const audio = document.getElementById("audio");
        audio.addEventListener('ended', function () {
          this.ifEnd = true
        });

        audio.load()
        setTimeout(function () {

          audio.play();

        }, 1000);


        audio.crossOrigin = 'anonymous'
        const canvas = this.$refs.canvas
        const ctx = canvas.getContext('2d');



        // 解析音频
        const audioCtx = new AudioContext()

        const source = audioCtx.createMediaElementSource(audio)
        const analyser = audioCtx.createAnalyser()
        analyser.fftSize = 512
        source.connect(analyser)
        analyser.connect(audioCtx.destination)
        // 获取频谱数据 fft 频谱图 0-255 8 bit
        const frequencyData = new Uint8Array(analyser.frequencyBinCount)


        // 绘制可视化效果
        const drawVisualization = () => {
          analyser.getByteFrequencyData(frequencyData)
          // 清空画布
          ctx.clearRect(0, 0, canvas.width, canvas.height)
          var count = 2;
          var sum = 0;
          while (count < 255) {
            sum += frequencyData[count]
            count = count * 2
          }

          // 值在 0 - 8
          const adjustIndex = Math.LOG2E * Math.log(sum) > 0 ? Math.LOG2E * Math.log(sum) : 0
          // 头像边框
          ctx.save()
          const border = 5
          ctx.arc(175, 175, 120 + adjustIndex + border, 0, 2 * Math.PI)
          ctx.fillStyle = "#81D1FA";
          ctx.fill()
          ctx.clip()

          ctx.beginPath();

          // ctx.save()
          const image = new Image();
          image.src = this.sceneImg

          // 头像绘制
          ctx.arc(175, 175, 120 + adjustIndex, 0, 2 * Math.PI)
          ctx.clip()
          ctx.drawImage(image, 55 - adjustIndex, 55 - adjustIndex, 240 + adjustIndex * 2, 240 + adjustIndex * 2);
          ctx.restore()

          ctx.save()
          ctx.translate(175, 175)

          const len = frequencyData.length / 3
          // 调节头像大小


          const angle = Math.PI * 2 / 3 / len// 三个区域 重复
          for (let j = 0; j < 3; j++) {
            for (let i = 0; i < len; i++) {
              ctx.save()
              //旋转坐标轴
              ctx.rotate(angle * i + Math.PI * 2 / 3 * j)
              ctx.beginPath()
              const h = frequencyData[i] / 255 * 40
              //x1,y1是起始坐标点,x2,y2是结束坐标点   线性
              //利用渐变来填充矩形
              var linear = ctx.createLinearGradient(120 + border + adjustIndex, 0, 120 + border + adjustIndex + h, 0);
              linear.addColorStop(0, 'rgb(178, 215, 242)');
              linear.addColorStop(1, "white");
              //ctx.rect(x, y, width, height); x ,y起始点坐标
              ctx.rect(120 + border + adjustIndex, 0, h, 2);
              ctx.fillStyle = linear
              ctx.fill()
              ctx.restore();
            }
          }

          ctx.restore()
          if (!this.ifEnd) {
            requestAnimationFrame(drawVisualization)
          }

        }

        requestAnimationFrame(drawVisualization)
      }

    }
  }
};
</script>

<style scoped>
:root {
  --offset: 1.2px;
}


.body {
  display: block;
  height: 60vh;
  width: 100%;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
}

.slide {
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  display: block;
  height: 25vh;
}

.footer {
  justify-content: center;
  align-items: center;
  height: 15vh;
  display: flex;
  width: 100%;
}

h2 {
  width: 1200px;
  font-size: 30px;
  font-family: Helvetica;
  line-height: 1.06;
  letter-spacing: -0.02em;
  color: #1d1d1f;
}

.mask {
  height: 31.79px;
  position: relative;
  overflow: hidden;
  margin-top: var(--offset);
}

.mask span {

  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 31.5px;
  color: rgb(20, 47, 88);
  padding-bottom: var(--offset);
  left: 38%;
  background-size: 100% 100%;
  /* -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-repeat: no-repeat; */
}

.mask span[data-show] {
  transform: translateY(-100%);
  transition: .5s transform ease-in-out;
}

audio {
  display: none;
}

.mask span[data-up] {
  transform: translateY(-200%);
  transition: .5s transform ease-in-out;
}

#honeycomb {
  width: 100%;
  height: 100%;
  background: center url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(157, 206, 240)' viewBox='0 0 100 169.5'%3E%3Cpolygon points='50,34.75 93.5,59.75 93.5,109.75 50,134.75 6.5,109.75 6.5,59.75'%3E%3C/polygon%3E%3Cpolygon points='0,-50 43.5,-25 43.5,25 0,50 -43.5,25 -43.5,-25'%3E%3C/polygon%3E%3Cpolygon points='100,-50 143.5,-25 143.5,25 100,50 56.5,25 56.5,-25'%3E%3C/polygon%3E%3Cpolygon points='0,119.5 43.5,144.5 43.5,194.5 0,219.5 -43.5,194.5 -43.5,144.5'%3E%3C/polygon%3E%3Cpolygon points='100,119.5 143.5,144.5 143.5,194.5 100,219.5 56.5,194.5 56.5,144.5'%3E%3C/polygon%3E%3C/svg%3E");
  background-size: 80px;
}

.circle {
  width: 100%;
  height: 70%;
  /* background-color: white; */
  justify-content: center;
  align-items: center;
  display: flex;
}

.logo_list {
  width: 100%;
  height: 10%;
  justify-content: center;
  /* align-items: center; */
  display: flex;

}

.logo_wrapper {
  display: block;
  margin: 0px 20px;
  width: 3%;
  height: 3vw;
  border: 2px solid rgb(178, 215, 242);
  border-radius: 5px;
  background: rgb(142, 179, 203, 50%);

}

.left_fist {
  animation-name: leftMove;
  animation-duration: 2s;
}

.right_fist {
  animation-name: rightMove;
  animation-duration: 2s;
}

@keyframes leftMove {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(10%);
  }
}

@keyframes rightMove {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-10%);
  }
}

.logo_wrapper div {
  text-align: center;
  font-size: 4vmin;
}

.back {
  width: 100%;
  height: 20%;

}

.fistbump {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-button {
  position: absolute;
  top: 10px;
  right: 20px;
  color: aliceblue;
}

.challenge {
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo {
  width: 350px;
  height: 350px;
  /* background-color: #3498db; */
  /* 设置背景颜色 */
  border-radius: 50%;
  /* 使用 border-radius 创建圆形 */


}

.wrapper {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  /* background-color: #3498db; */
  justify-content: center;
  align-items: center;
  display: flex;
  /* overflow: hidden; */
}

.photo img {
  width: 100%;
  height: 100%;

}

.challenge button {
  background-color: rgb(240, 58, 23);
  border: 4px solid black;
  text-align: center;
  font-size: 25px;
  line-height: 0px;
}

.fist {
  font-size: 15vmin;
  float: left;
}
</style>