<template>
  <div>
    <div class="container">
      <svg width="157px" height="126px" viewBox="0 0 157 126" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Heart-Group</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Desktop" transform="translate(-267.000000, -301.000000)" stroke="#000000" stroke-width="20">
            <g id="Heart-Group" transform="translate(278.000000, 311.000000)">
              <path
                d="M66.6562543,13.6106531 C115.669926,-30.8795812 193.14986,44.3686482 66.6562543,104.323088 C-54.0630308,44.3686482 16.35352,-31.3170812 66.6562543,13.6106531 Z"
                id="heart"></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script scope>
export default {

}
</script>

<style scoped>
.container {
  justify-content: center;
  align-items: center;
  display: flex;
}

#heart {
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-dashoffset: 400;
  stroke-dasharray: 400;
  /*animation: stroke 0.5s ease-in-out;*/
  animation: stroke 3s ease-in-out infinite alternate;

  stroke: rgb(242, 172, 210)
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}
</style>