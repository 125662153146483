<template>
  <div style="background-color: rgb(218, 228, 243); padding: 0 32px">
    <!-- 建议顶部和底部，高度写成px固定  假设顶部高度为a，底部高度为b-->
    <div id="player" style="height: 100px; width: 100%">
      <!-- 调成自己合适的尺寸-->
      <div style="width: 70%">
        <audio controls="controls" controlslist=" nodownload noplaybackrate" ref="audio"
          style="width: 90%; margin: 10px 30px"></audio>
      </div>
      <div style=" align-items: center;  display: flex;">
        <el-button id="form-button" type="primary" @click="submit">Submit</el-button>
        <el-button type="primary" @click="quit" style="margin-left: 20px; margin-right: 20px">Quit</el-button>
        <!-- <el-switch size="large" @change="switchVoice" v-model="ifUseOrigin" class="mb-2" inline-prompt
          active-text="开启原声" inactive-text="爱豆声线" :active-value="1" :inactive-value="0" /> -->
        <div>原声</div>
        <div style="display: inline-block; margin: 5px 5px;">
          <heartswitch @changeEvent="switchVoice" :changeError="changeError" />
        </div>
        <div>爱豆</div>
      </div>
    </div>
    <!-- 32px为左右间距各32px-->
    <div style="margin: 10px 0; padding: 0 32px">
      <!-- 假设顶部高度为a，底部高度为b ，此处150px为a+b-->
      <el-scrollbar id="exam" style="height: calc(100vh - 170px); width: 100%">
        <div class="form-box" style="padding: 10px;">
          <form id="form-box">
            <div v-for="paper in paperList">
              <div v-if="paper.questionType == 1">
                <div v-html="paper.stem"></div>
                <div v-for="answer in paper.answerList">
                  <input type="radio" :name='paper.questionId + "-" + paper.questionType + "-input"'
                    :value="answer.answerValue">
                  {{ answer.answer }}
                  <br>
                </div>
                <br>
              </div>
              <div v-if="paper.questionType == 0">
                <div v-html="paper.stem"></div>
                <div v-for="answer in paper.answerList">
                  <input type="checkbox" :name='paper.questionId + "-" + paper.questionType + "-input"'
                    :value="answer.answerValue" @change="limitChoice(paper.questionId)">
                  {{ answer.answer }}
                </div>
                <br>
              </div>
              <div v-if="paper.questionType == 2">
                <div v-html="format(paper)"></div>
                <br>
              </div>
              <!-- <div v-html="format(paper)"></div> -->
            </div>
          </form>
        </div>
      </el-scrollbar>
    </div>
    <el-footer style="width: 100%; height: 50px; background: rbg(218, 228, 243)"></el-footer>
  </div>
</template>
<script>

import { test } from "../../api/index";
import heartswitch from '@/components/plugger/heartswitch.vue'
export default {
  components: {
    heartswitch,
  },

  data() {
    return {
      changeError: false,
      paperList: [],
      answerList: {},
      request: {

      },
      total: 0,
      result: {},
      ifBeyond: ''
      // leftHeight: 0,
    };
  },
  created() {
    this.request.paperId = this.$route.query.paperId;
    this.request.idolId = this.$route.query.idolId;
    test.getPaperDetail(this.$route.query.paperId).then((res) => {
      if (res.code == 200) {
        this.ifBeyond = 1
        this.total = res.data.total;
        this.paperList = res.data.questionDTOS;
        this.paperList.forEach((paper) => {
          var key = paper.questionId + "-" + paper.questionType + "-input";
          if (!(key in this.answerList)) {
            this.answerList[key] = [];
          }
          for (var i = 0; i < paper.rightAnswer.length; i++) {
            this.answerList[key].push(paper.rightAnswer[i]);
          }
          // 将值添加到对应的数组中
        });
      } else {
        this.$message.error('您已达到今日的使用上限(2次/每日),会员无限次')
        this.$router.push({ name: 'main' })
      }

    });
  },
  mounted() {
    this.submit()

  },
  methods: {
    limitChoice(data) {
      var inputName = data + "-0-input";

      if ($("input[name='" + inputName + "']:checked").length >= 2) {
        $("input[name='" + inputName + "']").attr('disabled', true)
        $("input[name='" + inputName + "']:checked").attr('disabled', false)
      } else {
        $("input[name='" + inputName + "']").attr('disabled', false)

      }
    },
    switchVoice(data) {
      // switch会自动修改值 所以传来的ifUserOrgin都是已经被修改过的值
      if (!data) {
        // data == false 说明启用原声
        this.request.idolId = 0;
        test.getVoice(this.request).then((res) => {
          if (res.code == 200) {
            console.log('切换原声')
            this.$refs.audio.src = res.data;
            this.changeError = false;
          } else {
            this.changeError = true;
          }
        });
      } else {
        this.request.idolId = this.$route.query.idolId;
        test.getVoice(this.request).then((res) => {
          if (res.code == 200) {
            this.$refs.audio.src = res.data;
            this.changeError = false;
          } else {
            this.changeError = true;
          }
        });
      }
    },
    format(data) {
      var replacedContent = data.stem.replace(/{{response}}/g, function () {
        var inputName = data.questionId + "-" + data.questionType + "-input";
        var inputElement = '<input type="text" name="' + inputName + '">';

        return inputElement;
      });
      return replacedContent;

    },
    compareWords(word1, word2) {
      // 将两个单词转换为小写并进行比较

      return word1.toLowerCase() === word2.toLowerCase();
    },
    submit() {
      //  1.监听表单的提交事件
      $("#form-button").on("click", (e) => {
        e.preventDefault(); // 2.阻止表单的默认行为
        var formdata = $("#form-box").serialize();
        var data = {}; // 3. 快速获取表单中的数据
        // 按照 & 分割每个键值对
        const pairs = formdata.split("&");
        // 遍历每个键值对

        pairs.forEach((pair) => {
          // 按照 = 分割键和值
          const [key, value] = pair.split("=");
          // 检查键是否已存在，如果不存在则创建一个新的数组
          if (!(key in data)) {
            //单选多选一整道填空各自对应一个key
            data[key] = [];
          }
          // 将值添加到对应的数组中
          data[key].push(decodeURIComponent(value).trim());
        });

        // 总得分

        var score = 0;

        //如果单选多选题不选 formdata是拿不到任何值的 所以要补充空题
        Object.keys(this.answerList).forEach((key) => {
          var answersheet = data[key];
          const regex = /(?:-)(\d+)/;
          var type = key.match(regex)[1];
          this.result[key] = [];
          // 如果没选
          if (!(key in data)) {
            //单选多选填空各自对应一个key
            data[key] = [];
            if (type == 1) {
              data[key].push('')
              this.result[key].push(0)
            } else {
              data[key].push('')
              data[key].push('')
              this.result[key].push(0)
              this.result[key].push(0)
            }
          } else {

            // 如果选了
            //answersheet就是用户的答案

            var rightAnswer = this.answerList[key];
            //如果是单选题 
            if (type == 1) {
              if (rightAnswer.indexOf(answersheet[0]) > -1) {
                score++;
                this.result[key].push(1)
              } else {
                this.result[key].push(0)
              }

            } else if (type == 0) {
              var diverse = rightAnswer.length - answersheet.length
              // 多选题
              for (var i = 0; i < answersheet.length; i++) {
                if (rightAnswer.indexOf(answersheet[i]) > -1) {
                  score++;
                  this.result[key].push(1)
                } else {
                  this.result[key].push(0)
                }
              }
              for (var i = 0; i < diverse; i++) {
                this.result[key].push(0)
                data[key].push('')
              }
            } else {
              //如果是填空题 因为空有多个填法 需要拆开判断
              for (var i = 0; i < answersheet.length; i++) {
                var answerSplit = rightAnswer[i];
                if (answerSplit.indexOf(";") > -1) {
                  answerSplit = answerSplit.split(";");
                  for (var j = 0; j < answerSplit.length; j++) {
                    //忽略大小写比较 遍历正确写法
                    var ret = this.compareWords(answerSplit[j], answersheet[i]);
                    if (ret) {
                      score++;
                      this.result[key].push(1)
                      break;
                    }
                  }
                  this.result[key].push(0)

                } else if (this.compareWords(answerSplit, answersheet[i])) {
                  this.result[key].push(1)
                  score++;
                } else {
                  this.result[key].push(0)
                }
              }
            }
          }
        });


        //答案
        console.log(JSON.stringify(this.answerList))
        // console.log(this.result)
        // console.log(data)
        this.$store.commit("setAnswerList", this.answerList)
        //用户填写的实际答案
        this.$store.commit("setActualAnswer", data);
        //判题细节 哪个对哪个错
        this.$store.commit("setErrorDetail", this.result);
        this.$router.push({
          name: "score",
          query: {
            score: score,
            total: this.total,
            paperId: this.request.paperId,
            idolId: this.request.idolId,
          },
        });
      });
    },
    quit() {
      const vm = this;
      vm.$router.push({ name: "kieltsView" });
    },
  },
  watch: {
    ifBeyond: {
      handler(newVal, oldVal) {
        if (newVal == 1) {
          test.getVoice(this.request).then((res) => {
            if (res.code == 200) {
              this.$refs.audio.src = res.data;
            }

          });
        }
      }
    }
  }

};
</script>

<style scoped>
#exam {
  height: 100%;
}

.form-box {
  padding: 10px;
  background-color: rgb(228, 234, 235);
  border-radius: 8px;
}

#player {
  width: 100%;
  align-items: center;
  background-color: rgb(218, 228, 243);
  display: flex;
}
</style>
