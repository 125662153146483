<template>
  <div class="container">
    <div class="left">
      <div class="ad">
        <div>{{ title }}</div>
        <p>Learn with who you love. Love who you learn with.</p>
        <p>ความรักเป็นการเอกลักษณ์ที่เกินกว่า AI</p>
        <p>사랑은 AI를 초월하는 존재입니다.</p>
        <p>愛はAIを超越する存在です。</p>
        <p>爱是超越AI的存在。</p>
      </div>
    </div>
    <div class="right">
      <div class="right_up">
        <div class="login">
          <h1 style="margin-bottom: 8px;">Get started.</h1>
          <el-form :model="loginForm" class="login-container" ref="loginData" :rules="rules" lable-position="left"
            label-width="0px">
            <el-form-item prop="email" style="margin-bottom: 15px;">
              <el-input type="text" v-model="loginForm.email" autocomplete="off" placeholder="账号(邮箱)"
                :trim="true"></el-input>
            </el-form-item>
            <el-form-item prop="password" style="margin-bottom: 5px;">
              <el-input show-password type="password" v-model="loginForm.password" autocomplete="off"
                placeholder="密码"></el-input>
            </el-form-item>
          </el-form>
          <div class="tip">
            <div style="margin-left: 5px;">
              <el-checkbox v-model="checked" label="记住我" size="large" />
            </div>
            <!-- <div>
              <a href="#" @click="showRegisterPopup">忘记密码？</a>
            </div> -->

          </div>
          <div class="submitbutton">

            <button type="submit" ref="loginButton" :disabled="ableToClick" @click="login">
              <div class="button-loader" aria-hidden="true"></div>
              <div class="button-text">登录</div>
            </button>
            <!-- <el-button color="#626aef" id="loginbutton" type="primary" @click="login()" :disabled="ableToClick"
                style="text-align: center;">登录</el-button> -->

            <el-button id="checkbutton" type="primary" plain @click="onShow">点此验证</el-button>
          </div>
          <Vcode :show="isShow" @success="success" @close="close" />

          <!-- <div>服务修整中 暂时关闭注册服务了哦</div> -->
          <div>没有账号？<a href="#" @click="showRegisterPopup">点我注册</a></div>
          <div class="contract"> 点击登录或注册，即同意
            <router-link tag="a" target="_blank" to="/UserContract">服务条款</router-link> 和 <router-link tag="a"
              target="_blank" to="/UserPrivacy">隐私政策</router-link>。
          </div>
        </div>

      </div>

      <div class="right_down">
        <div class="font-extralight"><a href="https://beian.miit.gov.cn/" target="_blank">
            苏ICP备2024088230号</a>
        </div>
        <div class="between">
          © 2024 kpop奇思妙想
        </div>
        <div class="app">
          更多内容 💌
          <div class="redbook">
            <a href="https://www.xiaohongshu.com/user/profile/5c88c5610000000010004072" target="_blank">
              <img src="../../assets/red-icon.png" style="height: 100%; width: 100%;" alt="Red">
            </a>
          </div>
          <div class="douyin">
            <a href="https://www.douyin.com/user/MS4wLjABAAAAOQC6U_1TSMBT1gyUyKkozjDMTTxF3vmrC12X-VWd4HHA1rfZfP3rJPxbfgkh9jRn"
              target="_blank">
              <img src="../../assets/douyin.png" style="height: 100%; width: 100%;" alt="Douyin">
            </a>
          </div>
        </div>

      </div>
    </div>

    <div class="register">
      <el-dialog v-model="dialogTableVisible" title="欢迎注册" style="text-align: center; width: 320px;">
        <el-form :model="registerForm" class="login-container" ref="registerData" :rules="rules" lable-position="left"
          label-width="0px">
          <el-form-item prop="userName">
            <el-input type="text" v-model="registerForm.userName" autocomplete="off" placeholder="用户名"
              style="width: 300px;" :trim="true"></el-input>
          </el-form-item>
          <el-form-item prop="email">
            <el-input type="text" v-model="registerForm.email" autocomplete="off" placeholder="账号(邮箱)"
              style="width: 300px;" :trim="true"></el-input>
          </el-form-item>
          <div class="submitbutton">
            <el-form-item prop="code">
              <el-input type="text" v-model="registerForm.code" autocomplete="off" placeholder="验证码"
                style="width: 150px;" :trim="true"></el-input>
            </el-form-item>
            <el-button v-bind:class="{ grey: isGrey, blue: !isGrey }" v-bind:disabled="dis" type="primary"
              style="margin-left: 5px; width: 120px;" @click="getCode">
              <div v-if="show">发送验证码</div>
              <div v-else>重新发送({{ count }}s)</div>
            </el-button>

          </div>

          <el-form-item prop="password">
            <el-input show-password v-model="registerForm.password" autocomplete="off" placeholder="密码"></el-input>
          </el-form-item>

          <el-form-item prop="passwordtwice">
            <el-input show-password v-model="registerForm.passwordtwice" autocomplete="off"
              placeholder="确认密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button color="#626aef" type="primary" @click="register"
              style="text-align: center; margin: auto 50px; width: 200px;">注册</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <el-dialog v-model="contractDialog" title="Warning" width="500" center>
        <span>
          {{ contract }}
        </span>
        <template #footer>
          <div class="dialog-footer">
            <el-button type="primary" @click="contractDialog = false">
              确认
            </el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { user } from '../../../api/index';
import Vcode from "vue3-puzzle-vcode";
const Base64 = require("js-base64").Base64
export default {
  components: {
    Vcode
  },
  data() {
    //验证两次密码是否一致
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.registerForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      contractDialog: false,
      contract: "",
      ableToClick: true,
      index: 0,
      initialtitle: 'Welcome To K-WHAT World',
      title: '',
      checked: false,
      isShow: false,
      show: true,
      isGrey: false, //按钮样式
      timer: null, //设置计时器,
      count: "",
      loginForm: {
        email: '',
        password: ''
      },
      registerForm: {
        userName: '',
        email: '',
        password: '',
        passwordtwice: '',
        code: ''
      },
      dialogTableVisible: false,
      rules: {
        email: [
          { type: 'email', required: true, pattern: '[^ \x22]+', message: '请输入正确邮箱地址', trigger: 'blur' },
        ],
        userName: [
          { type: 'string', required: true, pattern: '[^ \x22]+', message: '请输入用户名', trigger: 'blur' },
          { min: 1, max: 12, message: '用户名长度过长(12位)', trigger: 'blur' }
        ],
        password: [
          { type: 'string', required: true, pattern: '[^ \x22]+', message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 12, message: '密码长度在6-12位之间', trigger: 'blur' }
        ],
        passwordtwice: [
          { type: 'string', required: true, pattern: '[^ \x22]+', message: '请再次输入密码', trigger: 'blur' },
          { min: 6, max: 12, message: '密码长度在6-12位之间', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur', required: true }
        ],
        code: [
          { type: 'string', required: true, pattern: '[^ \x22]+', message: '请输入验证码', trigger: 'blur' },
          { min: 6, max: 6, message: '验证码长度为6位', trigger: 'blur' }

        ]
      }
    }
  },
  mounted() {

    setInterval(() => {
      this.type()
    }, 200)

    let email = localStorage.getItem("email");
    if (email) {
      this.loginForm.email = localStorage.getItem("email");
      this.loginForm.password = Base64.decode(localStorage.getItem("password"));// base64解密
      this.checked = true;
    }
  },
  methods: {

    showService() {
      const routeUrl = this.$router.resolve({
        name: "privacy",
      });
      window.open(routeUrl.href, "_blank");
    },
    type() {
      this.index++
      this.title = this.initialtitle.slice(0, this.index)
    },
    getCode() {
      var reg = new RegExp("^(?!^\\s)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$", 'g');
      if (reg.test(this.registerForm.email)) {
        this.registerForm.eventType = 1
        user.verifyCode(this.registerForm)
          .then(
            res => {
              if (res.code == 200) {
                this.$message.success('邮件正在发送中,验证码有效期为20min,请不要着急哦！')
              } else if (res.code == 202) {
                this.$message.error('该邮箱已被注册')
              } else {
                this.$message.error('邮件已经发送，请不要重复点击，请注意查收！')
              }
            }
          )
        let TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.isGrey = true;
          this.show = false;
          this.dis = true;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.dis = false;
              this.isGrey = false;
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }

      } else {
        this.$message.error('邮件地址有误，请您再检查一下')
      }
    },
    register() {
      this.$refs.registerData.validate((valid) => {
        if (valid) {
          user.register(this.registerForm)
            .then(
              res => {

                if (res.code == 200) {
                  this.dialogTableVisible = false
                  this.$message.success('您已注册成功，让我们开始冒险吧！(*^▽^*)')
                } else if (res.code == 203) {
                  this.$message.error('验证码错误')
                } else {
                  this.$message.error('请检查昵称是否带有表情符号')
                }
              })
        } else {
          this.$message.error('注册信息不符合要求，请您再检查一下')

        }
      })
    },
    login() {

      //组件的vue实例
      this.$refs.loginData.validate((valid) => {

        if (valid) {

          var button_css = this.$refs.loginButton.classList.value
          this.$refs.loginButton.classList.value = this.$refs.loginButton.classList.value + 'loading'

          user.login(this.loginForm)
            .then(
              res => {

                if (res.code == 200) {
                  localStorage.setItem("token", res.data.token)
                  localStorage.setItem("email", this.loginForm.email)
                  let password = Base64.encode(this.loginForm.password);
                  if (this.checked) {
                    localStorage.setItem("password", password);
                  }
                  this.$router.push({ name: 'kieltsView' })

                } else {

                  localStorage.removeItem("email");
                  localStorage.removeItem("password");
                  this.$refs.loginButton.classList.value = button_css
                  this.$message.error('账号或密码错误，请您再检查一下')
                }
              })
        } else {
          this.$message.error('账户(邮箱)或密码缺失，请您再检查一下')
        }
      })

    },
    onShow() {
      this.isShow = true;

    },
    // 用户通过了验证
    success() {
      this.isShow = false; // 通过验证后，需要手动隐藏模态框
      this.ableToClick = false
    },
    // 用户点击遮罩层，应该关闭模态框
    close() {
      this.isShow = false;
    },
    showRegisterPopup() {
      this.dialogTableVisible = true

    }
  },
  watch: {
    checked: {
      handler(newValue, oldValue) {
        if (!newValue) {

          localStorage.removeItem("email");
          localStorage.removeItem("password");

        }
      }
    }
  }
}
</script>


<style>
.redbook {
  width: 40px;
  height: 40px;
  display: flex;
}

.app {

  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #999999;
}

.douyin {
  padding-bottom: 2.5px;
  width: 40px;
  height: 41px;
  display: flex;
}

.font-extralight {
  padding-left: 10px;
  height: 20px;
}

.grey {
  background-color: #EDEEF1;
  border-color: #EDEEF1;
  color: white;
  width: 30%;
}

.blue {
  background-color: rgb(64, 158, 255);
  border-color: rgb(64, 158, 255);
}

* {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.register {
  font-family: Arial, sans-serif;
  text-align: center;
}

.container {
  display: flex;
  height: 100vh;
}


.left {
  background: linear-gradient(90deg, rgba(249, 169, 203, 1) 0%, rgba(195, 190, 254, 1) 31%, rgba(167, 218, 245, 1) 82%);
  width: 61.8%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.right {
  flex: 4;
}

.right_up {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 45px)
}

.right_down {
  height: 40px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  justify-content: space-between;
  white-space: nowrap;
}

.submitbutton {
  text-align: center;
  display: flex;

}

.ad {
  text-align: center;


}

.ad p {
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 5px;
}

#checkbutton {
  margin-bottom: 10px;
  margin-right: 0px;
  font-size: 12px;
}

.login {
  width: 300px;
  max-width: 100%;
  padding: 20px;

  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;

}

.between {
  color: #999999;
  font-size: 12px;
}

.ad p {
  font-size: 20px;

}

.font-extralight a:link {
  font-size: 10px;
  color: #999999;
  text-decoration: none;
}

.font-extralight a:visited {
  font-size: 10px;
  color: #999999;
  text-decoration: none;
}

.font-extralight a:hover {
  font-size: 10px;
  color: #999999;
  text-decoration: none;
}

.contract a:link {
  font-size: 12px;
  color: #999999;
  text-decoration: none;
}

.contract a:visited {
  font-size: 12px;
  color: #999999;
  text-decoration: none;
}

.contract a:hover {
  font-size: 12px;
  color: #999999;
  text-decoration: none;
}

.ad div {
  font-size: 40px;
  font-weight: 700;
}

.login h2 {
  text-align: center;
  margin-bottom: 10px;
}

.tip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  /* 垂直居中对齐 */
}

.submitbutton {
  justify-content: space-between;
}

button {
  height: 35px;
  border: 0;
  font-size: 16px;
  color: white;
  background: rgb(98, 106, 239);
  cursor: pointer;
  overflow: hidden;
  border-radius: 50px;
  padding: 1rem 4rem;
  display: grid;
  align-items: center;
  justify-items: center;
}

.button-loader {
  width: 20px;
  height: 20px;
  border-width: 4px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  animation: rotate 1s linear infinite;
  position: relative;
  top: -100px;
  transition: .3s ease-in;
}

button.loading {
  pointer-events: none;
}

button.loading .button-loader {
  top: -12px;
}

button>div {
  grid-area: 1 / 1 / 1 / 1;
}

button[disabled] {
  background: rgb(177, 181, 247);
  cursor: no-drop
}

.button-text {
  letter-spacing: 4px;
  position: relative;
  top: -12px;
  transition: 0.3s top ease-out;
}

.contract {
  margin-top: 10px;
  font-size: 12px;
  color: #999999;
}

button.loading .button-text {
  top: 100px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
