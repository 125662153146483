<template>
  <div>
    <div>
      <el-row>
        <el-button :class="{ active: 0 == upBtn }" @click="showRecord(0)">IELTS</el-button>
        <el-button :class="{ active: 1 == upBtn }" @click="showRecord(1)">CET4</el-button>
        <el-button :class="{ active: 2 == upBtn }" @click="showRecord(2)">CET6</el-button>
      </el-row>
    </div>
    <div v-show="recordsList">

      <el-table empty-text="您尚未进行挑战，所以暂无记录哦！" :data="recordsList" style="width: 100%">
        <el-table-column prop="testName" label="考题分类" width="180">

        </el-table-column>
        <el-table-column prop="paperIndex" label="题型分类" width="100">

          <template v-slot="scope">
            <div v-if="upBtn == 0">
              Part {{ scope.row.paperIndex }}
            </div>
            <div v-else>
              Section
              <span v-if="scope.row.paperIndex == 1"> A </span>
              <span v-else-if="scope.row.paperIndex == 2"> B </span>
              <span v-else-if="scope.row.paperIndex == 3"> C </span>
            </div>
          </template>


        </el-table-column>
        <el-table-column prop="score" label="得分" width="100">
          <template v-slot="scope">
            {{ scope.row.score }}/ <span><strong>{{ scope.row.totalScore }}</strong> </span>
          </template>
        </el-table-column>

        <el-table-column prop="idolName" label="Cha Mate" width="100">

        </el-table-column>
        <el-table-column prop="createTime" label="Cha Time" width="200">

        </el-table-column>
        <el-table-column label="详情">
          <template v-slot="scope">
            <el-button color="rgba(167, 218, 245, 1)" @click="checkDetail(scope.row)" round>查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>

</template>

<script scoped>
import { test } from '../../api/index';

export default {
  data() {
    return {
      upBtn: 0,
      request: {
      },
      recordsList: []
    }
  },

  created() {
    this.request.testType = 0
    test.getRecord(this.request)
      .then(res => {
        if (res.code == 200) {
          this.recordsList = res.data
        }

      })
  },

  mounted() {

  },
  methods: {
    showRecord(data) {
      this.request.testType = data
      this.upBtn = data
      test.getRecord(this.request)
        .then(res => {
          this.recordsList = res.data
        })
    },
    checkDetail(data) {
      this.$router.push({
        name: "viewOriginalArticle",
        query: {
          paperId: data.paperId,
          idolId: data.idolId,
          score: data.score,
          total: data.total,
          challengeId: data.challengeId,
          beforePage: 1
        },
      });
    }
  },
}
</script>


<style scoped>
.active {
  color: #1890ff;
  border-color: #badeff;
  background-color: #e8f4ff
}
</style>
