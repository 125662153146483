<template>

  <div>

    <!-- 父盒子 -->
    <label class="box">
      <!-- 复选框，有选中状态 -->
      <input @change="inputchecked" type="checkbox" v-model="checkStatus" :onChange="changeVoice">

      <!-- 心形图标 -->
      <svg viewBox="0 0 33 23" fill="pink">
        <path
          d="M23.5,0.5 C28.4705627,0.5 32.5,4.52943725 32.5,9.5 C32.5,16.9484448 21.46672,22.5 16.5,22.5 C11.53328,22.5 0.5,16.9484448 0.5,9.5 C0.5,4.52952206 4.52943725,0.5 9.5,0.5 C12.3277083,0.5 14.8508336,1.80407476 16.5007741,3.84362242 C18.1491664,1.80407476 20.6722917,0.5 23.5,0.5 Z">
        </path>
      </svg>
    </label>

  </div>

</template>


<script>
export default {
  data() {
    return {
      checkStatus: true,
    };
  },
  props: ["changeError"],
  methods: {
    changeVoice() {
      this.$emit("changeEvent", this.checkStatus)
    }
  },
  watch: {
    changeError: function (newData) {
      if (newData) {
        //如果发生请求错误
        if (this.checkStatus) {
          this.checkStatus = false
        } else {
          this.checkStatus = true
        }
      }
    }
  }
}
</script>

<style scoped>
* {
  /* 常规初始化 */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* 解决手机浏览器点击有选框的问题 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

div {
  /* 常规居中显示，简单背景色 */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  min-height: 25px;

}

.box {
  /* 整个父盒子区域都可以点，是个小手 */
  cursor: pointer;
  /* 过渡动画时间，主要是按下缩小一圈 */
  transition: transform 0.2s;

  position: relative;
}

.box:active {
  /* 点击时，缩小一圈 */
  transform: scale(0.95);
}

.box svg {
  /* 中间心形图标的宽高，撑开整个开关区域 */
  width: 4vmin;
  height: 3vmin;
  /* background-color: skyblue; */

  /* 中间填充颜色 */
  fill: #ffffff;
  /* 描边颜色，描边头是圆润的 */
  stroke: #d6d6ee;
  stroke-linejoin: round;

  /* 过渡动画时间 */
  transition: all 0.4s;
}

.box input:checked+svg {
  /* 开关打开时修改心形图标颜色 */
  fill: #ee6688;
  stroke: #ee4477;
}

.box input {
  /* 去除默认复选框样式 */
  appearance: none;

  /* 中间滑动圆圈的宽高，简单白色背景 */
  width: 2vmin;
  height: 2vmin;
  border-radius: 50%;
  background-color: #ffffff;
  /* 灰色阴影 */
  box-shadow: 0.05vmin 0.2vmin rgba(0, 0, 0, 0.2);
  /* 鼠标小手 */
  cursor: pointer;

  /* 初始位置，具体值要根据实际情况微调 */
  position: absolute;
  top: 0.25vmin;
  left: 0.15vmin;

  /* 开关未打开时，默认使用关闭的动画，然后停在初始位置 */
  animation: animate-off 0.4s linear forwards;
}

.box input:checked {
  /* 开关打开时，使用打开动画，然后停在打开的位置 */
  animation: animate 0.4s linear forwards;
}

@keyframes animate {

  /* 动画就是简单的位置变换，要根据情况调整 */
  0% {
    top: 0.25vmin;
    left: 0.15vmin;
  }

  25% {
    top: 0.55vmin;
    left: 0.5vmin;
  }

  50% {
    top: 0.7vmin;
    left: 1vmin;
    /* 到正中间时圆大一小圈 */
    transform: scale(1.05);
  }

  75% {
    top: 0.55vmin;
    left: 1.5vmin;
  }

  100% {
    top: 0.25vmin;
    left: 1.85vmin;
  }
}

@keyframes animate-off {

  /* 关闭的动画就是反着来 */
  0% {
    top: 0.25vmin;
    left: 1.85vmin;
  }

  25% {
    top: 0.55vmin;
    left: 1.5vmin;
  }

  50% {
    top: 0.7vmin;
    left: 1vmin;
    transform: scale(1.05);
  }

  75% {
    top: 0.55vmin;
    left: 0.5vmin;
  }

  100% {
    top: 0.25vmin;
    left: 0.15vmin;
  }
}
</style>