import axios from 'axios'
import { data } from 'jquery'

export default {
  // 登录
  login: (data) => {
    return axios.request({
      url: 'api/user/login',
      data: data,
      method: 'post'
    }).then(res => {
      return res.data
    })
  },
  //注册
  register: (data) => {
    return axios.request({
      url: 'api/user/register',
      data: data,
      method: 'post'
    }).then(res => {
      return res.data
    })
  },
  //登陆验证
  verifyCode: (data) => {
    return axios.request({
      url: 'api/user/verifyCode',
      data: data,
      method: 'post'
    }).then(res => {
      return res.data
    })
  },

  //退出
  logout: (data) => {
    return axios.request({
      url: 'api/user/logout',
      data: data,
      method: 'post'
    }).then(res => {
      return res.data
    })
  },
  //修改密码
  changePass: (data) => {
    return axios.request({
      url: 'api/user/changePass',
      data: data,
      method: 'post'
    }).then(res => {
      return res.data
    })
  }
}