<template>
  <div class="echarts_box">
    <el-dialog v-model="dragDialogVisible" title="解析" :draggable="true" :close-on-click-modal="false" width="500"
      :modal="false">
      <div v-html="explain"></div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="dragDialogVisible = false">
            确认
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>

  <div>

    <!-- -->
    <div style="background-color: rgb(218, 228, 243); padding: 0 32px">
      <!-- 建议顶部和底部，高度写成px固定  假设顶部高度为a，底部高度为b-->
      <div id="player" style="height: 100px; width: 100%">
        <!-- 调成自己合适的尺寸-->
        <div style="width: 70%">
          <audio ref="audio" controls="controls" controlslist=" noplaybackrate nodownload" @timeupdate="adjustText"
            style="width: 90%; margin: 10px 30px"></audio>
        </div>
        <div style=" align-items: center;  display: flex;">
          <el-button type="primary" @click="quit" style="margin-right: 20px">Quit</el-button>
          <div>原声</div>
          <div style="display: inline-block; margin: 5px 5px;">
            <heartswitch @changeEvent="switchVoice" :changeError="changeError" />
          </div>
          <div>爱豆</div>
        </div>
      </div>
      <!-- 32px为左右间距各32px-->
      <div style="margin: 10px 0; padding: 0 32px">
        <!-- 假设顶部高度为a 100px，底部高度为b 70px ，此处170px为a+b  form-box 高度为100vh- 170px -->
        <div class="form-box" style="padding: 10px;">
          <form id="form-box" style="overflow-x: hidden">
            <el-scrollbar id="exam">
              <form id="form-box">
                <div v-for="paper in paperList">
                  <div v-if="paper.questionType == 1">
                    <div v-html="paper.stem"></div>
                    <div v-for="answer in paper.answerList">
                      <input type="radio" :name='paper.questionId + "-" + paper.questionType + "-input"'
                        :value="answer.answerValue">
                      {{ answer.answer }}
                      <br>
                    </div>
                    <br>
                  </div>
                  <div v-if="paper.questionType == 0">
                    <div v-html="paper.stem"></div>
                    <div v-for="answer in paper.answerList">
                      <input type="checkbox" :name='paper.questionId + "-" + paper.questionType + "-input"'
                        :value="answer.answerValue" @change="limitChoice(paper.questionId)">
                      {{ answer.answer }}
                    </div>
                    <br>
                  </div>
                  <div v-if="paper.questionType == 2">
                    <div v-html="format(paper)"></div>
                    <br>
                  </div>
                  <!-- <div v-html="format(paper)"></div> -->
                </div>
              </form>
            </el-scrollbar>
          </form>
          <el-tabs type="border-card" style="border-radius: 8px; background-color: rgb(228, 234, 235); width: 50%;">
            <el-tab-pane label="我的答题" style="height: calc(100vh - 220px)">
              <el-table :data="tableData" height="calc(100vh - 220px)" border style="width: 100%; padding-bottom: 80px"
                row-class-name="row-class">
                <el-table-column prop="question_number" width="60" align="center" label="题号">
                  <template #default="scope">
                    <div v-show="this.setOff != -1">
                      {{ scope.row.question_number + this.setOff }}
                    </div>

                  </template>
                </el-table-column>
                <el-table-column prop="right" width="60" align="center" label="结果">
                  <template #default="scope">
                    <div style="height: 35px ;" v-if="scope.row.right">
                      ✔️
                    </div>
                    <div v-else>
                      ❌️
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="right_answer" width="180" align="center" label="正确答案">
                </el-table-column>
                <el-table-column prop="answer" width="180" align="center" label="我的答案">
                </el-table-column>
                <el-table-column prop="explain" width="120" align="center" label="解析">
                  <template #default="scope">
                    <el-button @click="showExplain(scope.row.question_number)">解析</el-button>
                  </template>
                </el-table-column>
              </el-table>

            </el-tab-pane>
            <el-tab-pane label="原文" style="height: calc(100vh - 170px)">
              <div style="height: 100%;" ref="wrapper">

                <ul class="textScroller" ref="textTop">
                  <li v-for="(item, index) in originText" :key="index" @click="changeActiveIndex(index)"
                    :class="{ active: index === activeIndex }">
                    {{ item.playText }}
                  </li>
                </ul>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <el-footer style="width: 100%; height: 50px; background: rbg(218, 228, 243)"></el-footer>

    </div>






  </div>
</template>

<script>
import { test } from "../../api/index";
import heartswitch from '@/components/plugger/heartswitch.vue'

function parsetime(time) {
  const timeArr = time.split(":");
  return +timeArr[0] * 60 + + timeArr[1];
}
export default {
  components: {
    heartswitch,

  },
  name: "KieltsAppViewOriginalArticle",

  data() {
    return {
      dragDialogVisible: false,
      ifUseOrigin: 0,
      paperList: [],
      answerList: {},
      request: {
        paperId: 1,
        idolId: 1,
      },
      total: 0,
      answerList: {}, //答案
      actualAnswer: {}, //原题
      score: 0, //成绩
      total: 0, //总分
      errorDetail: {},
      tableData: [],
      originText: [],
      textToShow: "",
      activeIndex: "",
      ifLoaded: 0,
      explainList: [],
      explain: '',
      setOff: -1
    };
  },
  created() {
    this.request.paperId = this.$route.query.paperId;
    this.request.idolId = this.$route.query.idolId;
    //原文数据加载
    this.getText(this.$route.query)


    //原题数据加载
    test.getPaperDetail(this.$route.query.paperId).then((res) => {
      if (res.code == 200) {

        this.total = res.data.total;
        this.setOff = res.data.setOff;
        this.paperList = res.data.questionDTOS;
        console.log(this.setOff)
      }

    });

    test.getExplain(this.$route.query.paperId).then((res) => {
      this.explainList = res.data;

    });

  },
  mounted() {

    test.getVoice(this.request).then((res) => {
      if (this.$refs.audio) {
        this.$refs.audio.src = res.data;
      }

    });

    if (this.$route.query.beforePage) {
      this.getAnswerInfo()
    } else {
      var answer = this.$store.state.answerList; //正确答案
      var actual = this.$store.state.actualAnswer; //选择的答案
      var detail = this.$store.state.errorDetail;
      this.answerList = JSON.parse(JSON.stringify(answer));
      this.actualAnswer = JSON.parse(JSON.stringify(actual));
      this.errorDetail = JSON.parse(JSON.stringify(detail));
      this.score = this.$route.query.score;
      this.total = this.$route.query.total;
      this.ifLoaded = 1
      // 数据加载完毕
    }

  },

  methods: {
    showExplain(data) {
      this.dragDialogVisible = true

      this.explain = this.explainList[data - 1]
    },
    setDetail() {


      // 答题数据加载
      // 题目序号
      var sort = 1;

      Object.keys(this.answerList).forEach((key) => {
        var rightAnswer = this.answerList[key];
        var userAnswer = this.actualAnswer[key];
        const regex = /(?:-)(\d+)/;
        var type = key.match(regex)[1];

        //单选题 
        if (type == 1) {
          this.tableData.push({
            question_number: sort++,
            right: this.errorDetail[key][0] == 1 ? true : false,
            right_answer: rightAnswer[0],
            answer: userAnswer[0],
          });
        } else if (type == 2) {
          // 填空题
          for (var i = 0; i < this.answerList[key].length; i++) {
            this.tableData.push({
              question_number: sort++,
              right: this.errorDetail[key][i] == 1 ? true : false,
              right_answer: rightAnswer[i],
              answer: userAnswer[i],

            });
          }

        } else {

          this.tableData.push({
            question_number: sort++,
            right: this.errorDetail[key].includes(0) ? false : true,
            right_answer: rightAnswer,
            answer: userAnswer,
          });
          sort++

        }

      })
    },
    // 从答题记录过来的要多一步加载
    getAnswerInfo() {
      test.getRecordDetail(this.$route.query.challengeId)
        .then(res => {
          this.answerList = JSON.parse(res.data.answerList);
          this.actualAnswer = JSON.parse(res.data.userAnswer);
          this.errorDetail = JSON.parse(res.data.userResult);
          this.ifLoaded = 1
          // 数据加载完毕

        })
    },
    getText(data) {
      test.getText(data).then(
        res => {
          if (res.data) {
            let text = res.data.split("\n");
            for (var i = 0; i < text.length; i++) {
              // 每一行的台词
              var lineText = text[i].split("|")
              this.originText.push(
                {
                  playTime: parsetime(lineText[0].trim()),
                  playText: lineText[1]
                }
              )
            }

          }

        }

      )
    },
    changeActiveIndex(data) {
      this.activeIndex = data

      this.$refs.audio.currentTime = this.originText[data].playTime
    },
    adjustText() {

      this.timer = window.setTimeout(() => {
        var index = this.originText.length - 1;

        // 这里的判断是为了退出该页面是该函数还在运行 而 audio已经销毁的时候会报错
        if (this.$refs.audio) {
          const currentTime = this.$refs.audio.currentTime
          for (let i = 0; i < this.originText.length; i++) {
            if (this.originText[i].playTime > currentTime) {
              index = i - 1;
              break;
            }
          }
          this.activeIndex = index
          this.$nextTick(() => {
            var height = this.$refs.wrapper.offsetHeight / 2 - 20;
            let lyc = document.querySelectorAll('.textScroller li')[index] //获取当前播放的歌词
            if (lyc) {
              if (lyc.offsetTop > height)
                lyc.parentNode.scrollTop = lyc.offsetTop - height

            }
          })
        }


      }, 2000);


    },
    switchVoice(data) {

      // switch会自动修改值 所以传来的ifUserOrgin都是已经被修改过的值
      if (!data) {
        // data == false 说明启用原声
        this.request.idolId = 0;
        test.getVoice(this.request).then((res) => {
          if (res.code == 200) {
            this.$refs.audio.src = res.data;
            this.getText(this.request)
            this.changeError = false;
          } else {
            this.changeError = true;
          }
        });
      } else {
        this.request.idolId = this.$route.query.idolId;
        test.getVoice(this.request).then((res) => {
          if (res.code == 200) {
            this.$refs.audio.src = res.data;
            this.getText(this.request)
            this.changeError = false;
          } else {
            this.changeError = true;
          }
        });
      }
    },
    quit() {
      if (this.$route.query.beforePage) {
        this.$router.push({ name: 'testRecord' })

      } else {
        this.$router.push({ name: "kieltsView" });
      }

    },

    nullArray(arr) {
      let result = [];
      if (!arr.length) {
        result.push("未填写");
      }
      for (let key in arr) {
        if (arr[key]) {
          result.push(arr[key]);
        }
      }
      return result;
    },
    format(data) {
      var replacedContent = data.stem.replace(/{{response}}/g, function () {
        var inputName = data.questionId + "-" + data.questionType + "-input";
        var inputElement = '<input type="text" name="' + inputName + '">';

        return inputElement;
      });
      return replacedContent;

    },
  },
  watch: {
    ifLoaded(newVal, oldVal) {
      if (newVal === 1) {
        this.setDetail()
      }
    }
  }

};
</script>

<style scoped>
.form-box {
  display: flex;
  position: relative;
  overflow: hidden;
  height: calc(100vh - 170px);
  background-color: rgb(228, 234, 235);
  border-radius: 8px;
}

#originText {
  background-color: #fff;
  padding: 15px
}

li {
  list-style-type: none;
  cursor: pointer;
}

#originText p {
  margin: 5xp 5px;
}

.form-box>* {
  flex-basis: calc(100% / 2);
}

.el-tabs__item {
  border-radius: 8px 8px 0 0;

}

:deep() .el-tabs--border-card {
  border: none;
}

:deep() .el-tabs__content {
  padding: 0px;
}

:deep() .row-class {
  line-height: 80px;
  height: 80px;
}

li {
  margin: 10px 25px;
}

li:hover {
  color: rgb(156, 172, 177);
  transform: scale(1.05);
  transform-origin: 10% 50%;
}

#player {
  width: 100%;
  align-items: center;
  background-color: rgb(218, 228, 243);
  display: flex;
}

.error {
  color: red;
}


.textScroller {
  background: white;
  height: calc(100vh - 208px);
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  right: -17px;
}

.active {
  color: rgb(64, 158, 255);
  transform: scale(1.05);
  transform-origin: 10% 50%;
}

::v-deep .el-dialog {
  pointer-events: auto;
}

.echarts_box {
  pointer-events: none;
}
</style>
