<template>
  <div>
    <div v-show="testsList.length">
      <el-row>
        <div v-if="state == 0">
          <el-button id="ielts-box" v-for="(ieltsOrder, index) in ieltsList" @click="showTests(ieltsOrder, index)"
            :class="{ active: index == upBtn }"> 剑{{ ieltsOrder
            }}</el-button>
        </div>
        <div v-else>
          <el-button id="ielts-box" v-for="(ieltsOrder, index) in ieltsList" @click="showTests(ieltsOrder, index)"
            :class="{ active: index == upBtn }">
            {{ ieltsOrder }}年</el-button>

        </div>

      </el-row>
    </div>
    <div id="test">
      <el-table :data="test.paperDTO" empty-text="每日更新，敬请期待哦！" :cell-class-name="getCellClass"
        v-for="(test, index) in testsList" style="width: 100%">

        <el-table-column prop="paperIndex" :label="test.testName" width="180" min-width="180">
          <template v-slot="scope">
            Part {{ scope.row.paperIndex }}
          </template>
        </el-table-column>
        <el-table-column prop="paperType" label="题目类型" width="180" min-width="180" />
        <el-table-column label="练习答题" width="180" min-width="180">
          <template v-slot="scope">
            <el-button color="rgba(167, 218, 245, 1)" @click="btnClick(scope.row)" round>练习</el-button>
          </template>
        </el-table-column>
        <el-table-column label="Cha Time" width="220" min-width="180">
          <template v-slot="scope">
            <el-button color="rgba(167, 218, 245, 1)" @click="btnClickChallenge(scope.row)" round>陪你学💕</el-button>
          </template>
        </el-table-column>
        <el-table-column label="支持爱豆声线" prop="idolDTOS" :min-width="widthList[index]">
          <template v-slot="scope">
            <div class="block">
              <el-tooltip :content="val.idolName" placement="bottom" v-for="(val, index) in scope.row.idolDTOS">
                <el-button id="idol-img" type="primary" circle>
                  <el-avatar>
                    <img :src="getSrc(val)">
                  </el-avatar>
                </el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog v-model="dialogVisible" :title="'选择你的爱豆声线:' + this.idolName" width="30%" :before-close="closeForm">
      <div style="height: 100px;">
        <el-button color="rgba(167, 218, 245, 1)" id="idol-img" v-for="idol in idolList" type="primary"
          :class="{ 'selected': this.selectedButton === idol.idolId }" @click="handleClick(idol)" circle>
          <el-avatar>
            <img :src="getSrc(idol)">
          </el-avatar>
        </el-button>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeForm">取 消</el-button>
        <el-button type="primary" @click="goExam()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog customClass="customWidth" v-model="dialogVisible_cha" :title="'选择与你一起Cha的爱豆:' + this.idolName"
      :before-close="closeForm">
      <div style="height: 100px;">
        <el-button color="rgba(167, 218, 245, 1)" id="idol-img" v-for="idol in idolList" type="primary"
          :class="{ 'selected': this.selectedButton === idol.idolId }" @click="handleClick(idol)" circle>
          <el-avatar>
            <img :src="getSrc(idol)">
          </el-avatar>
        </el-button>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeForm">取 消</el-button>
        <el-button type="primary" @click="goChallege">确 定</el-button>
      </div>
    </el-dialog>

    <div class="loading-wrapper" v-show="!testsList.length">
      <loading></loading>
    </div>

  </div>
</template>

<script scoped>
import { jwtDecode } from "jwt-decode";
import { test } from '../../api/index';
import loading from '../components/plugger/loading.vue'
export default {
  components: {
    loading,
  },
  data() {
    return {
      upBtn: 0,
      selectedButton: -1,
      ieltsList: [],
      testsList: [],
      idolList: [],
      testSearchReq: {},
      dialogVisible: false,
      // cha模式
      dialogVisible_cha: false,
      paperId: 1,
      idolId: 0,
      idolImg: '',
      idolName: '',
      state: '',
      widthList: []
    }

  },

  created() {
    this.state = this.$route.query.state
    test
      .getTestTable(this.$route.query.state)
      .then(
        res => {
          if (res.code == 200) {
            this.ieltsList = res.data
            this.showTests(this.ieltsList[0], 0)
          }
        })

  },



  methods: {
    getSrc(val) {
      // const str = '../assets/idol/menu/mark.png'

      if (val.idolImg.indexOf('http') != -1) return val.idolImg
      // return require(str)
      // const name = 'mark'
      return require(`../assets/idol/menu/${val.idolImg}.png`)
      // return require('../assets/idol/menu/mark.png')
    },
    getWidthList() {
      this.widthList = []
      if (Array.isArray(this.testsList)) {
        this.testsList.map(item => {
          this.widthList.push(this.getColumnWidth(item, 'idolDTOS'))
        })

      }
    },
    getColumnWidth(data, prop) {

      const contentWidths = data.paperDTO.map((item) => {
        const length = Array.isArray(item[prop]) ? item[prop].length : 0;
        return 80 * length
      });
      return Math.max(...contentWidths)
    },
    getCellClass({ row, column, rowIndex, columnIndex }) {

      if (column.property === 'idolDTOS') {
        return 'idolDTOSCol'
      } else {
        return ''
      }
    },
    closeForm() {
      this.idolName = ''
      this.selectedButton = -1
      this.dialogVisible_cha = false
      this.dialogVisible = false
    },
    btnClick(data) {
      if (data.idolDTOS.length == 0) {
        this.$message.error('暂未开放，敬请期待')
      } else {
        this.dialogVisible = true
        this.paperId = data.paperId
        this.idolList = data.idolDTOS
      }
    },
    btnClickChallenge(data) {
      //vip
      var token = localStorage.getItem("token")
      var decoded = jwtDecode(token);
      if (decoded.userType == 1) {
        if (data.idolDTOS.length == 0) {
          this.$message.error('暂未开放，敬请期待')
        } else {
          this.dialogVisible_cha = true
          this.paperId = data.paperId
          this.idolList = data.idolDTOS
        }

      } else {
        this.$message.error('您的账户目前不支持该项功能哦,请选择右上角头像升级权益哦')
      }

    },
    showTests(data, index) {

      this.upBtn = index
      this.testSearchReq.testType = this.$route.query.state
      this.testSearchReq.testOrder = data
      test.getTests(this.testSearchReq)
        .then(
          res => {
            this.testsList = res.data
            this.getWidthList()
          })
    },
    goExam() {
      if (this.idolId == 0) {
        this.$message.error('您尚未选择爱豆声线')
      } else {
        this.dialogVisible = false,

          this.$router.push({ name: 'ieltsExam', query: { paperId: this.paperId, idolId: this.idolId } })
      }

    },
    goChallege() {
      if (this.idolId == 0) {
        this.$message.error('您尚未选择爱豆声线')
      } else {
        this.dialogVisible_cha = false,
          this.$router.push({ name: 'challenge', query: { paperId: this.paperId, idolId: this.idolId } })
      }

    },
    handleClick(data) {
      this.idolId = data.idolId
      this.idolName = data.idolName
      this.selectedButton = data.idolId
      this.idolImg = data.idolImg
    },
    showIdols(data) {
      test.getAvailableIdol(data)
        .then(
          res => {
            this.idolList = res.data
          }
        )
    }
  }
}
</script>

<style scoped>
#ielts-box {
  display: inline-block;
  /* 将按钮显示为行内块元素 */
  margin: 5px;
  /* 按钮之间的间距 */
}

#idol-img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  float: left;
}

* {
  font-family: "fangzheng";
  text-align: center;
  transition: all .3s;
  font-size: 20px;
}

:deep(.el-dialog) {
  width: 350px;
}

.selected {
  background: #409eff;
  color: white;
}

.active {
  color: #1890ff;
  border-color: #badeff;
  background-color: #e8f4ff
}

.loading-wrapper {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
}
</style>
