import { createRouter, createWebHashHistory } from 'vue-router'
import mainVue from '../components/mainVue.vue'
import ieltsTest from '../components/ieltsTest.vue'
import ieltsExam from '../components/ieltsExam.vue'
import kieltsView from '../views/kielts/kieltsView.vue'
import login from "../views/user/login.vue";
import score from "../components/score.vue";
import viewOriginalArticle from "../components/viewOriginalArticle.vue";
import challenge from '../components/challenge.vue';
import challengeExam from '../components/challengeExam.vue'
import testRecord from '../components/testRecord.vue'
import UserContract from '../views/contract/UserContract.vue'
import UserPrivacy from '../views/contract/UserPrivacy.vue'
const routes = [
  {
    path: "/",
    component: kieltsView,
    name: "kieltsView",
    redirect: "/main",
    meta: { authRequired: true },
    children: [
      {
        name: "main",
        path: "/main",
        component: mainVue,
      },
      {
        path: "/ielts",
        name: "ieltsTest",
        component: ieltsTest,
      },
      {
        path: "/cet6",
        name: "ieltsTest",
        component: ieltsTest,
      },
      {
        path: "/cet4",
        name: "ieltsTest",
        component: ieltsTest,
      },
      {
        path: "/record",
        name: "testRecord",
        component: testRecord
      }
    ],
  },
  {
    path: "/UserContract",
    name: "UserContract",
    component: UserContract,
  },
  {
    path: "/UserPrivacy",
    name: "UserPrivacy",
    component: UserPrivacy,
  },
  {
    path: "/test/exam",
    name: "ieltsExam",
    component: ieltsExam,
    meta: { authRequired: true },
  },
  {
    path: "/challenge/exam",
    name: "challengeExam",
    component: challengeExam,
    meta: { authRequired: true },
  },

  {
    path: "/challenge",
    name: "challenge",
    component: challenge,
    meta: { authRequired: true },
  },
  {
    path: "/ielts/score",
    name: "score",
    component: score,
    meta: { authRequired: true },
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/viewOriginalArticle",
    name: "viewOriginalArticle",
    component: viewOriginalArticle,
    meta: { authRequired: true },
  },
];

const router = createRouter({
  base: '/',
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  // const token = localStorage.getItem('token')
  // if (to.name !== 'login' && !token) next({ name: 'login' })
  // else next()
  if (to.meta.authRequired) {
    //存在token正常跳转
    if (localStorage.getItem('token')) {
      next()
    } else {
      next({ path: '/login', query: { redirect: to.fullPath } })
    }
  } else {
    next()
  }

})

export default router;