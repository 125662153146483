<template>

  <div class="main">
    <div class="text">
      <div class="title">
        <p class="bold">
          隐私政策
        </p>
      </div>
      <div class="content">
        欢迎您访问我们的产品。Kwhat爱豆听力（包括网站等产品提供的服务，以下简称“产品”）确保用户的数据安全和隐私保护是产品的首要任务，本隐私政策表明了您访问和使用产品和服务时所收集的数据及其处理方式。<br><br>


        请您在继续使用产品前务必认真仔细阅读并确认充分理解本隐私政策全部规则和要点，一旦您选择使用，即视为您同意本隐私政策的全部内容，同意产品按其收集和使用您的相关信息。<br><br>


        如您在阅读过程中，对本政策有任何疑问，可联系产品的客服咨询，请通过hi@bibigpt.co或产品中的反馈方式与产品取得联系。如您不同意相关协议或其中的任何条款的，您应停止使用产品和服务。<br><br>


        本隐私政策帮助您了解以下内容：<br><br>


        一、产品如何收集和使用您的个人信息；<br><br>


        二、产品如何存储和保护您的个人信息；<br><br>


        三、产品如何共享、转让、公开披露您的个人信息；<br><br>


        四、产品如何使用Cookie和其他追踪技术；<br><br>


      </div>

      <div class="litte_title">
        一、产品如何收集和使用您的个人信息
      </div>
      <div class="content">
        个人信息是指以电子或者其他方式记录的能够单独或者与其他信息，结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。产品根据相关法律法规的要求，并严格遵循正当、合法、必要的原则，出于您使用提供的服务和/或产品等过程中而收集和使用您的个人信息，包括但不限于电子邮箱地址、偏好及兴趣等。
        <br> <br>
        为接受产品全面的服务，您应首先注册一个用户账号，产品将通过它记录相关的数据。您所提供的所有信息均来自于您本人在注册时提供的数据。您准备使用的账户名、密码、您本人的联系方式，产品可能通过发短信或者邮件的方式来验证您的身份是否有效。
      </div>
      <div class="litte_title">
        二、产品如何存储和保护您的个人信息
      </div>
      <div class="content">
        作为一般规则，产品仅在实现信息收集目的所需的时间内保留您的个人信息。产品会在对于管理与您之间的关系严格必要的时间内保留您的个人信息（例如，当您创建帐户，从产品获取服务时）。<br><br>
        出于遵守法律义务或为证明某项权利或合同满足适用的诉讼时效要求的目的，产品可能需要在上述期限到期后保留您存档的个人信息，并且无法按您的要求删除。当您的个人信息对于产品的法定义务或法定时效对应的目的或档案不再必要时，产品确保将其完全删除或匿名化。<br><br>

        产品使用符合业界标准的安全防护措施保护您提供的个人信息，并加密其中的关键数据，防止其遭到未经授权访问、公开披露、使用、修改、损坏或丢失。产品会采取一切合理可行的措施，保护您的个人信息。产品会使用加密技术确保数据的保密性；产品会使用受信赖的保护机制防止数据遭到恶意攻击。<br><br>

        值得一提的是，为了加强对隐私数据的保护，产品在收集时就已对其进行了脱敏处理，即使在产品自己的数据库中，也不会储存具有关联性的、明文的隐私数据。<br><br>
      </div>

      <div class="litte_title">
        三、产品如何共享、转让、公开披露您的个人信息
      </div>
      <div class="content">
        在管理产品的日常业务活动所需要时，为追求合法利益以更好地服务客户，产品将合规且恰当的使用您的个人信息。出于对业务和各个方面的综合考虑，产品仅自身使用这些数据，不与任何第三方分享。<br><br>

        产品可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。在符合法律法规的前提下，当产品收到上述披露信息的请求时，产品会要求必须出具与之相应的法律文件，如传票或调查函。产品坚信，对于要求提供的信息，应该在法律允许的范围内尽可能保持透明。<br><br>

        在以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：<br><br>

        1.与国家安全、国防安全直接相关的；<br><br>
        2.与犯罪侦查、起诉、审判和判决执行等直接相关的；<br><br>
        3.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br><br>
        4.您自行向社会公众公开的个人信息；
        5.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。<br><br>
        6.根据个人信息主体要求签订和履行合同所必需的；
        7.用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；<br><br>
        8.法律法规规定的其他情形。<br><br>
      </div>
      <div class="litte_title">
        四、产品如何使用 Cookie 和其他追踪技术
      </div>
      <div class="content">
        为确保产品正常运转，产品会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、产品名称以及一些号码和字符。借助于
        Cookie，产品能够存储您的偏好或商品等数据，并用以判断注册用户是否已经登录，提升服务和产品质量及优化用户体验。<br><br>

        产品出于不同的目的使用各种 Cookie，包括：严格必要型 Cookie、性能 Cookie、营销 Cookie 和功能 Cookie。某些 Cookie 可能由外部第三方提供，以向产品提供其它功能。产品不会将 Cookie
        用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。<br><br>

        您可以清除计算机上或手机中保存的所有 Cookie，大部分网络浏览器都设有阻止或禁用 Cookie 的功能，您可对浏览器进行配置。阻止或禁用 Cookie
        功能后，可能影响您使用或不能充分使用产品的产品和服务。<br><br>
      </div>

    </div>
  </div>

</template>


<script>
export default {

}
</script>

<style scoped>
.content {
  margin: 10px 10px;
  color: rgb(6, 41, 47);
}

.text {
  width: 80%;
  margin: auto;
}

.litte_title {
  border-bottom: 2px solid #d3cfcf;
  padding: 10px 0px;
  font-weight: bold;
  font-size: 25px;
  margin-top: 20px;
}

.title {
  padding: 10px 0px;
  font-weight: bold;
  font-size: 30px;
  margin-top: 20px;
  border-bottom: 2px solid #d3cfcf;
}
</style>