import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router/router'
import $, { error } from "jquery";
import "@/assets/font/font.css";
import store from "./store";
import Vcode from "vue3-puzzle-vcode";
import axios from "axios";
import { ElMessage } from "element-plus";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.directive('debounce', {
  inserted(el, binding) {
    el.addEventListener('click', e => {
      el.classList.add('is-disabled')
      el.disabled = true
      setTimeout(() => {
        el.disabled = false
        el.classList.remove('is-disabled')
      }, 2000)//我这里设置的是2000毫秒也就是2秒
    })
  }
})
app.use(ElementPlus).use(store)
app.use(router).use($).use(Vcode)
app.mount("#app");
// axios请求拦截
axios.interceptors.request.use((config) => {
  // 为请求头对象，添加Token验证的token字段
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  if (token) {
    config.headers["token"] = token;
    config.headers["email"] = email;
  }
  return config;
});

axios.interceptors.response.use((res) => {
  if (res.data.code === 403) {
    localStorage.removeItem("token");
    // ElMessage.warning("登录有效期已过，麻烦重新登录下啦");
    router.replace({
      path: "/login",
      query: { redirect: router.currentRoute.fullPath }, //登录成功后跳入浏览的当前页面
    });
  }
  return res;
});
