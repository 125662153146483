<template>
  <div style="background-color: rgb(218, 228, 243); padding: 0 32px">
    <!-- 建议顶部和底部，高度写成px固定  假设顶部高度为a，底部高度为b-->
    <div id="player" style="height: 80px; ">
      <div id="menu" style="padding: 20px 32px; display: flex;">
        <div id="menu-border" style="display: flex;
    width: 100%;
    border: 3px ;
    border-radius: 30px;
    background-color: rgb(228, 234, 235);
    ">

          <div id="countTime" style="margin-left: 20px; height: 50px; display: flex; " @mouseenter="hover = true"
            @mouseleave="hover = false">
            <div v-show="leftTime > 0 && hover == true" style="height: 100%;display: flex;   font-family: 'fangzheng'">
              {{ tranformTime(leftTime) }}
            </div>
            <div v-show="hover == false" style="height: 100%; display: flex;">
              <svg t="1713780918879" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="1683" width="200" height="200" id="clock">
                <path
                  d="M1008.185895 221.122824c-57.098377-90.39743-136.996106-165.195304-230.893437-216.293851-19.299451-10.499702-43.398766-3.299906-53.79847 15.999545s-3.299906 43.398766 15.899548 53.798471c82.097666 44.598732 151.795685 109.996873 201.694266 188.994627 7.599784 11.999659 20.39942 18.499474 33.599045 18.499474 7.299792 0 14.599585-1.999943 21.199397-6.099827 18.499474-11.799665 24.099315-36.298968 12.299651-54.898439zM468.201245 336.919532c-21.899377 0-39.698871 17.799494-39.698871 39.698872v175.994996c0.299991 10.699696 4.899861 20.299423 11.999659 27.199227l79.097751 90.097439c7.799778 8.899747 18.799466 13.499616 29.799153 13.499616 9.299736 0 18.599471-3.299906 26.199255-9.899718 16.499531-14.499588 18.099485-39.498877 3.599898-55.998408l-71.397971-81.297689V376.618404c0.099997-21.899377-17.699497-39.698871-39.598874-39.698872z"
                  fill="#707070" p-id="1684"></path>
                <path
                  d="M507.900117 85.226687V43.127884c0-21.899377-17.799494-39.698871-39.698872-39.698871s-39.698871 17.799494-39.698871 39.698871v43.998749c-28.299196 3.099912-56.698388 8.79975-84.897587 17.399506-247.99295 74.897871-388.788948 337.590403-313.891077 585.583353 6.399818 20.999403 28.49919 32.799068 49.498593 26.499246 20.999403-6.299821 32.899065-28.49919 26.499247-49.498592C43.413321 461.016004 160.409995 242.72221 366.504136 180.523978c206.094141-62.198232 424.387936 54.698445 486.586168 260.792586 62.298229 206.094141-54.698445 424.387936-260.792587 486.586168-148.495779 44.898724-309.491202-2.399932-410.088342-120.496575-14.199596-16.699525-39.298883-18.699468-55.998408-4.499872-16.699525 14.199596-18.699468 39.298883-4.499872 55.998408C212.608511 965.301669 344.004776 1024 479.500924 1024c45.298712 0 91.09741-6.599812 135.796139-20.099429 247.99295-74.997868 388.68895-337.6904 313.79108-585.583353C870.7898 225.12271 698.494698 97.026352 507.900117 85.226687z"
                  fill="#707070" p-id="1685">

                </path>
              </svg>
            </div>

          </div>

          <!-- 调成自己合适的尺寸-->
          <div style="width: 70%">
            <audio ref="audio" style="width: 90%; margin: 10px 30px" @canplay="getTotalTime"></audio>
          </div>
          <div style=" align-items: center;  display: flex;">
            <el-button id="form-button" type="primary" @click="submit" ref="submit">Submit</el-button>
            <el-button type="primary" @click="quit" style="margin-left: 20px; margin-right: 20px">Quit</el-button>
            <!-- <el-switch size="large" @change="switchVoice" v-model="ifUseOrigin" class="mb-2" inline-prompt
          active-text="开启原声" inactive-text="爱豆声线" :active-value="1" :inactive-value="0" /> -->
            <!-- <div>原声</div>
        <div style="display: inline-block; margin: 5px 5px;">
          <heartswitch @changeEvent="switchVoice" :changeError="changeError" />
        </div>
        <div>爱豆</div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 32px为左右间距各32px-->
    <div style="margin: 10px 0; padding: 0 32px">
      <!-- 假设顶部高度为80，底部高度为50 ，此处110px为80+10+10+50-->
      <el-scrollbar id="exam" style="height: calc(100vh - 150px); width: 100%">
        <div class="form-box" style="padding: 10px;">
          <form id="form-box">
            <div v-for="paper in paperList">
              <div v-if="paper.questionType == 1">
                <div v-html="paper.stem"></div>
                <div v-for="answer in paper.answerList">
                  <input type="radio" :name='paper.questionId + "-" + paper.questionType + "-input"'
                    :value="answer.answerValue">
                  {{ answer.answer }}
                  <br>
                </div>
                <br>
              </div>
              <div v-if="paper.questionType == 0">
                <div v-html="paper.stem"></div>
                <div v-for="answer in paper.answerList">
                  <input type="checkbox" :name='paper.questionId + "-" + paper.questionType + "-input"'
                    :value="answer.answerValue" @change="limitChoice(paper.questionId)">
                  {{ answer.answer }}
                </div>
                <br>
              </div>
              <div v-if="paper.questionType == 2">
                <div v-html="format(paper)"></div>
                <br>
              </div>
              <!-- <div v-html="format(paper)"></div> -->
            </div>
          </form>
        </div>
      </el-scrollbar>
    </div>
    <el-footer style="width: 100%; height: 50px; background: rbg(218, 228, 243)"></el-footer>
  </div>
</template>
<script>

import { test } from "../../api/index";
import heartswitch from '@/components/plugger/heartswitch.vue'
export default {
  components: {
    heartswitch,
  },

  data() {
    return {
      ifSend: 0,
      changeError: false,
      paperList: [],
      answerList: {},
      request: {

      },
      hover: false,
      total: 0,
      result: {},
      // leftHeight: 0,
      leftTime: 0,
      totalTime: 0,
      messageShown: false,
    };
  },
  created() {
    this.request.paperId = this.$route.query.paperId;
    this.request.idolId = this.$route.query.idolId;
    test.getPaperDetail(this.$route.query.paperId).then((res) => {
      this.total = res.data.total;
      this.paperList = res.data.questionDTOS;
      this.paperList.forEach((paper) => {
        var key = paper.questionId + "-" + paper.questionType + "-input";
        if (!(key in this.answerList)) {
          this.answerList[key] = [];
        }
        for (var i = 0; i < paper.rightAnswer.length; i++) {
          this.answerList[key].push(paper.rightAnswer[i]);
        }
        // 将值添加到对应的数组中
      });
    });
  },
  mounted() {
    // 绑定监听事件
    this.submit()
    test.getVoice(this.request).then((res) => {
      this.$refs.audio.src = res.data;
      this.$refs.audio.play()
    });

    this.timer = setInterval(() => {
      this.countDown()
    }, 1000);
  },
  methods: {
    // 时间格式化
    tranformTime(data) {
      var minutes = Math.floor(data / 60); // 计算分钟数
      var remainingSeconds = Math.floor(data % 60); // 计算剩余的秒数
      var minutesString = (minutes < 10) ? "0" + minutes : minutes; // 将分钟数转换为字符串，并在个位数前添加零
      var secondsString = (remainingSeconds < 10) ? "0" + remainingSeconds : remainingSeconds; // 将剩余秒数转换为字符串，并在个位数前添加零

      return "倒计时 " + minutesString + " : " + secondsString;
    },
    getTotalTime() {
      this.totalTime = this.$refs.audio.duration
    },
    // 限制多选选项
    limitChoice(data) {
      var inputName = data + "-0-input";

      if ($("input[name='" + inputName + "']:checked").length >= 2) {
        $("input[name='" + inputName + "']").attr('disabled', true)
        $("input[name='" + inputName + "']:checked").attr('disabled', false)
      } else {
        $("input[name='" + inputName + "']").attr('disabled', false)

      }
    },
    // 倒计时
    countDown() {
      if (this.totalTime && this.$refs.audio) {
        this.leftTime = this.totalTime - this.$refs.audio.currentTime
      }

    },
    switchVoice(data) {
      // switch会自动修改值 所以传来的ifUserOrgin都是已经被修改过的值
      if (!data) {
        // data == false 说明启用原声
        this.request.idolId = 0;
        test.getVoice(this.request).then((res) => {
          if (res.code == 200) {
            this.$refs.audio.src = res.data;
            this.changeError = false;
          } else {
            this.changeError = true;
          }
        });
      } else {
        this.request.idolId = this.$route.query.idolId;
        test.getVoice(this.request).then((res) => {
          if (res.code == 200) {
            this.$refs.audio.src = res.data;
            this.changeError = false;
          } else {
            this.changeError = true;
          }
        });
      }
    },
    format(data) {
      var replacedContent = data.stem.replace(/{{response}}/g, function () {
        var inputName = data.questionId + "-" + data.questionType + "-input";
        var inputElement = '<input type="text" name="' + inputName + '">';

        return inputElement;
      });
      return replacedContent;
      // }
    },
    compareWords(word1, word2) {
      // 将两个单词转换为小写并进行比较

      return word1.toLowerCase() === word2.toLowerCase();
    },
    submit() {

      $("#form-button").on("click", (e) => {
        e.preventDefault(); // 2.阻止表单的默认行为
        var formdata = $("#form-box").serialize();
        var data = {}; // 3. 快速获取表单中的数据
        // 按照 & 分割每个键值对
        const pairs = formdata.split("&");
        // 遍历每个键值对

        pairs.forEach((pair) => {
          // 按照 = 分割键和值
          const [key, value] = pair.split("=");
          // 检查键是否已存在，如果不存在则创建一个新的数组
          if (!(key in data)) {
            //单选多选一整道填空各自对应一个key
            data[key] = [];
          }
          // 将值添加到对应的数组中
          data[key].push(decodeURIComponent(value).trim());
        });

        // 总得分

        var score = 0;

        //如果单选多选题不选 formdata是拿不到任何值的 所以要补充空题
        Object.keys(this.answerList).forEach((key) => {
          var answersheet = data[key];
          const regex = /(?:-)(\d+)/;
          var type = key.match(regex)[1];
          this.result[key] = [];
          // 如果没选
          if (!(key in data)) {
            //单选多选填空各自对应一个key
            data[key] = [];
            if (type == 1) {
              this.result[key].push(0)
            } else {
              data[key].push('')
              data[key].push('')
              this.result[key].push(0)
              this.result[key].push(0)
            }
          } else {

            // 如果选了
            //answersheet就是用户的答案

            var rightAnswer = this.answerList[key];
            //如果是单选题 
            if (type == 1) {

              if (rightAnswer.indexOf(answersheet[0]) > -1) {
                score++;
                this.result[key].push(1)
              } else {
                this.result[key].push(0)
              }

            } else if (type == 0) {
              var diverse = rightAnswer.length - answersheet.length
              // 多选题
              for (var i = 0; i < answersheet.length; i++) {
                if (rightAnswer.indexOf(answersheet[i]) > -1) {
                  score++;
                  this.result[key].push(1)
                } else {
                  this.result[key].push(0)
                }
              }
              for (var i = 0; i < diverse; i++) {
                this.result[key].push(0)
                data[key].push('')
              }
            } else {
              //如果是填空题 因为空有多个填法 需要拆开判断
              for (var i = 0; i < answersheet.length; i++) {
                var answerSplit = rightAnswer[i];
                if (answerSplit.indexOf(";") > -1) {
                  answerSplit = answerSplit.split(";");
                  for (var j = 0; j < answerSplit.length; j++) {
                    //忽略大小写比较 遍历正确写法
                    var ret = this.compareWords(answerSplit[j], answersheet[i]);
                    if (ret) {
                      score++;
                      this.result[key].push(1)
                      break;
                    }
                  }
                  this.result[key].push(0)

                } else if (this.compareWords(answerSplit, answersheet[i])) {
                  this.result[key].push(1)
                  score++;
                } else {
                  this.result[key].push(0)
                }
              }
            }
          }
        });
        // var token = localStorage.getItem("token")
        // var decoded = jwtDecode(token);
        // if (!this.ifSend && decoded.userType == 1) {
        if (!this.ifSend) {

          this.request.score = score;
          this.request.userResult = JSON.stringify(this.result);
          this.request.userAnswer = JSON.stringify(data);
          test.sendRecordDetail(this.request)
          this.ifSend = 1
        }

        //答案
        // console.log(this.answerList)
        // console.log(JSON.stringify(this.result))
        // console.log(JSON.stringify(data))
        this.$store.commit("setAnswerList", this.answerList);
        //用户填写的实际答案
        this.$store.commit("setActualAnswer", data);

        this.$store.commit("setErrorDetail", this.result);
        this.$router.push({
          name: "score",
          query: {
            score: score,
            total: this.total,
            paperId: this.request.paperId,
            idolId: this.request.idolId,
          },
        });
      });
    },
    quit() {
      const vm = this;
      vm.$router.push({ name: "kieltsView" });
    },
  },
  watch: {
    leftTime: {
      handler(newVal, oldVal) {
        if (!this.messageShown && oldVal > newVal && newVal <= 0) {
          // 解决提示报两次的问题
          this.messageShown = true
          this.$message.success('时间到了哦，学习的时间总是过得很快呢~')
          this.$refs.submit.$el.click();
        }
      }
    }
  }
};
</script>

<style scoped>
#exam {
  height: 100%;
}

.form-box {
  padding: 10px;
  background-color: rgb(228, 234, 235);
  border-radius: 8px;
}

* {
  align-items: center;
}

#countTime {
  width: 100%;
}

#clock {
  transform: scale(0.2);
  position: relative;
  margin: -50px;

}


#player {
  width: 100%;
  align-items: center;
  background-color: rgb(218, 228, 243);

}
</style>
