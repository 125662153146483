import axios from 'axios'
import { data } from 'jquery'

export default {
  // 查询
  getTestTable: (data) => {
    return axios.request({
      url: 'api/test/showTestTable/' + data,
      data: data,
      method: 'post'
    }).then(res => {
      return res.data
    })
  },
  getTests: (data) => {
    return axios.request({
      url: 'api/test/showTests',
      data: data,
      method: 'post'
    }).then(res => {
      return res.data
    })
  },
  getPaperDetail: (data) => {
    return axios.request({
      url: 'api/test/showOnePaper/' + data,
      data: data,
      method: 'post'
    }).then(res => {
      return res.data
    })
  },
  getVoice: (data) => {
    return axios.request({
      url: 'api/test/getVoice',
      data: data,
      method: 'post'
    }).then(res => {
      return res.data
    })
  },
  getText: (data) => {
    return axios.request({
      url: 'api/test/getText',
      data: data,
      method: 'post'
    }).then(res => {
      return res.data
    })
  },
  getRecord: (data) => {
    return axios.request({
      url: 'api/test/getRecord',
      data: data,
      method: 'post'
    }).then(res => {
      return res.data
    })
  },
  getRecordDetail: (data) => {
    return axios.request({
      url: 'api/test/getRecordDetail/' + data,
      data: data,
      method: 'post'
    }).then(res => {
      return res.data
    })
  },
  getChallengeVoice: (data) => {
    return axios.request({
      url: 'api/test/getChallengeVoice/',
      data: data,
      method: 'post'
    }).then(res => {
      return res.data
    })
  },
  getExplain: (data) => {
    return axios.request({
      url: 'api/test/getExplain/' + data,
      data: data,
      method: 'post'
    }).then(res => {
      return res.data
    })
  },
  sendRecordDetail: (data) => {
    return axios.request({
      url: 'api/test/sendRecordDetail',
      data: data,
      method: 'post'
    }).then(res => {
      return res.data
    })
  },
}