<template>
  <div class="common-layout">
    <el-container>
      <el-header style="--el-header-padding: 0px" class="title sticky" ref="headerRef">
        <div class="banner" ref="bannerRef" :style="{ height: scrollDirection == 1 ? '0px' : '50px' }">
          <div>感谢匿名用户通过参与小红书分享活动获得一周开屏和定制头像奖励，快参与小红书分享活动并@kpop奇思妙想,获得个人定制开屏和头像奖励!</div>
        </div>
        <div class="menu_line">
          <el-menu id="bg" ref="menuRef" router class="el-menu-demo" mode="horizontal">
            <div id="bullet" style="margin-right: 100px; margin-left: 20px;">k-what </div>
            <el-menu-item index="/ielts" class="menu" :route="{ name: 'ieltsTest', query: { state: 0 } }">I e l t
              s</el-menu-item>
            <el-menu-item index="/cet4" class="menu" :route="{ name: 'ieltsTest', query: { state: 1 } }">C E T
              4</el-menu-item>
            <el-menu-item index="/cet6" class="menu" :route="{ name: 'ieltsTest', query: { state: 2 } }">C E T
              6</el-menu-item>

          </el-menu>
          <div class="profile">
            <div class="head-logo">
              <el-dropdown>
                <span class="el-dropdown-link">
                  <el-avatar> <img src="../../assets/user.jpg" /> </el-avatar>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click.native="testRecord"><el-icon>
                        <Memo />
                      </el-icon>错题本</el-dropdown-item>
                    <el-dropdown-item @click.native="changePassWindow"><el-icon>
                        <Edit />
                      </el-icon>修改密码</el-dropdown-item>
                    <el-dropdown-item @click.native="uplevel"><el-icon>
                        <Medal />
                      </el-icon>升级权限</el-dropdown-item>
                    <el-dropdown-item @click.native="contact"><el-icon>
                        <PhoneFilled />
                      </el-icon>联系我们</el-dropdown-item>
                    <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </div>

      </el-header>
      <!-- 这里放入要显示的内容 -->
      <el-main class="app-main" :style="{ paddingTop: topHeight + 'px' }">
        <router-view :key="$route.fullPath"></router-view>
      </el-main>
    </el-container>
    <div class="changePassPop">
      <el-dialog v-model="dialogTableVisible" title="修改密码" style="text-align: center; ">
        <el-form :model="editForm" class="login-container" ref="editData" :rules="rules" lable-position="left"
          label-width="0px">
          <el-form-item prop="email">
            <el-input type="text" v-model="editForm.email" autocomplete="off" placeholder="账号(邮箱)" :trim="true"
              disabled></el-input>
          </el-form-item>
          <div class="submitbutton">
            <el-form-item prop="code">
              <el-input type="text" v-model="editForm.code" autocomplete="off" placeholder="验证码" style="width: 150px;"
                :trim="true"></el-input>
            </el-form-item>
            <el-button v-bind:class="{ grey: isGrey, blue: !isGrey }" v-bind:disabled="dis" type="primary"
              style="margin-left: 5px; width: 100px;" @click="getCode">
              <div v-if="show">发送验证码</div>
              <div v-else>重新发送({{ count }}s)</div>
            </el-button>

          </div>

          <el-form-item prop="password">
            <el-input show-password v-model="editForm.password" autocomplete="off" placeholder="密码"></el-input>
          </el-form-item>

          <el-form-item prop="passwordtwice">
            <el-input show-password v-model="editForm.passwordtwice" autocomplete="off" placeholder="确认密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="updatePass" :disabled="changeButton"
              style="text-align: center; margin: auto; width: 200px;">确认修改</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog v-model="upLeveldialog" title="升级权限" width="500" :before-close="handleCast">
        <div v-if="!seeDetail">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="right" label="权益" width="80" />
            <el-table-column prop="normal" label="普通用户" width="90" />
            <el-table-column prop="plus" label="升级用户" width="90" />
          </el-table>
        </div>
        <div v-else>
          <img src="../../assets/weidian.jpg" style="height: 100%; width: 100%;" alt="weidian">
        </div>
        <template #footer>
          <div class="dialog-footer">
            <el-button type="primary" @click="seeDetail = true">
              详情
            </el-button>
            <el-button type="primary" @click=" upLeveldialog = false; seeDetail = false">
              确认
            </el-button>
          </div>
        </template>
      </el-dialog>
      <el-dialog v-model="contactdialog" title="联系我们" width="500" :before-close="handleClose">
        网站有问题？还需要别的功能？<br>可以直接私信开发者小红书：
        <div class="redbook">
          <a href="https://www.xiaohongshu.com/user/profile/5bb0f07905d695000101a8fb" target="_blank">
            <img src="../../assets/red-icon.png" style="height: 100%; width: 100%;" alt="Red">
          </a>
        </div>
        期待与你一同建设！❤
        <template #footer>
          <div class="dialog-footer">

            <el-button type="primary" @click="   contactdialog = false">
              确认
            </el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>

import { user } from '../../../api/index';
import { jwtDecode } from "jwt-decode";

export default {
  data() {   //验证两次密码是否一致
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.editForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      seeDetail: false,
      scrollDirection: -1,
      topHeight: 60,
      contactdialog: false,
      dialogTableVisible: false,
      upLeveldialog: false,
      tableData: [
        {
          right: '练习模式',
          normal: '2次/日',
          plus: '无限次',
        },
        {
          right: '陪伴系统',
          normal: '❌',
          plus: '✔️',
        },
        {
          right: '原文精听',
          normal: '❌',
          plus: '✔️',
        },
        {
          right: '答案解析',
          normal: '❌',
          plus: '✔️',
        },
        {
          right: '错题集',
          normal: '❌',
          plus: '✔️',
        }, {
          right: '价格',
          normal: '免费',
          plus: '9.9r/月',
        },
      ],
      editForm: {
        email: '',
        password: '',
        passwordtwice: '',
        code: ''
      },
      changeButton: false,
      isShow: false,
      show: true,
      isGrey: false, //按钮样式
      timer: null, //设置计时器,
      count: "",
      rules: {
        password: [
          { type: 'string', required: true, pattern: '[^ \x22]+', message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 12, message: '密码长度在6-12位之间', trigger: 'blur' }
        ],
        passwordtwice: [
          { type: 'string', required: true, pattern: '[^ \x22]+', message: '请再次输入密码', trigger: 'blur' },
          { min: 6, max: 12, message: '密码长度在6-12位之间', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur', required: true }
        ],
        code: [
          { type: 'string', required: true, pattern: '[^ \x22]+', message: '请输入验证码', trigger: 'blur' },
          { min: 6, max: 6, message: '验证码长度为6位', trigger: 'blur' }

        ]
      }
    };
  },
  mounted() {
    this.topHeight = this.$refs.bannerRef?.clientHeight + 60;
    window.addEventListener("wheel", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("wheel", this.handleScroll);
  },
  methods: {
    handleCast() {
      this.upLeveldialog = false;
      this.seeDetail = false
    },
    contact() {
      this.contactdialog = true
    },
    uplevel() {
      this.upLeveldialog = true
    },
    handleScroll(event) {
      this.scrollDirection = event.deltaY > 0 ? 1 : -1;
      this.$nextTick(() => {
        this.topHeight = this.$refs.bannerRef?.clientHeight + 60;
      });
    },
    logout() {
      user.logout().then(
        res => {
          if (res.code == 200) {
            localStorage.removeItem("token")
          }
          this.$router.push({ name: 'login' })
        })
    },
    changePassWindow() {
      this.dialogTableVisible = true
      this.editForm.email = localStorage.getItem("email")
    },
    getCode() {
      var reg = new RegExp("^(?!^\\s)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$", 'g');
      if (reg.test(this.editForm.email)) {
        this.editForm.eventType = 2
        user.verifyCode(this.editForm)
          .then(
            res => {
              if (res.code == 200) {
                this.$message.success('邮件已发送，请查收')
              } else {
                this.$message.error('服务器繁忙，请稍等')
              }
            }
          )
        let TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.isGrey = true;
          this.show = false;
          this.dis = true;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.dis = false;
              this.isGrey = false;
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }

      }
    },
    updatePass() {
      this.$refs.editData.validate((valid) => {
        this.changeButton = true
        user.changePass(this.editForm)
          .then(
            res => {
              if (res.code == 200) {
                this.$message.success('密码修改成功')
              } else {
                this.$message.error('密码修改失败')
              }
            }
          )
        this.changeButton = false
        this.dialogTableVisible = false
      })

    },
    testRecord() {
      //vip
      // if(jwtDecode(localStorage.getItem("token")))
      var token = localStorage.getItem("token")
      var decoded = jwtDecode(token);
      if (decoded.userType == 1) {
        this.$router.push({ name: 'testRecord' })
      } else {
        this.$message.error('您的账户目前不支持该项功能哦,请升级账户')

      }

    }
  },
};
</script>
<style>
.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.banner {
  font-family: "fangzheng";
  text-align: center;
  transition: all 0.3s;
  font-size: 18px;
  line-height: 50px;
  overflow: hidden;
  white-space: nowrap;
  background-color: rgba(167, 218, 245, 1);
}

body {
  margin: 0px;
}

.app-main {
  width: 80%;
  margin: 0 auto;
  background-color: #fff;
}

#bullet {
  font-family: "ka1";
  text-align: center;
  font-size: 20px;
  margin-top: 15px;
  margin-left: 10px;
  white-space: nowrap;
}

.profile {
  /* display: flex; */
  /* text-align: right; */
  align-items: center;
}

.head-logo {
  display: inline-block;
  margin-top: 10px;
  margin-right: 20px;
}



.menu {
  font-family: "ka1";
  text-align: center;
  font-size: 25px;
  margin-top: 15px;
  margin-left: 10px;

}

.el-dialog {
  width: 300px;
}

.el-menu--horizontal>.el-sub-menu .el-sub-menu__title:hover {
  background-color: transparent
}



#bg {
  width: 95%;
  display: flex;
  background: linear-gradient(90deg,
      rgba(249, 169, 203, 1) 0%,
      rgba(195, 190, 254, 1) 31%,
      rgba(167, 218, 245, 1) 82%);
}

.menu_line {
  display: flex;
  background: rgba(167, 218, 245, 1);
}
</style>
