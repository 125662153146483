<template>

  <div class="main">
    <div class="text">
      <div class="title">
        <p class="bold">
          kwhat爱豆听力网站许可及用户服务协议
        </p>
      </div>
      <div class="content">
        欢迎使用 kwhat爱豆听力！本使用条款和条件（以下简称为“条款”）规范您使用 kwhat爱豆听力（以下简称为“产品”）AI 产品及其在https://kwhat.fun
        （以下简称为“网站”）上提供的服务（以下简称为“服务”）。
        <br>
        通过使用服务，表示您同意遵守这些条款。如果您不同意这些条款，请勿使用服务。
      </div>

      <div class="litte_title">
        1. 服务
      </div>
      <div class="content">
        本网站提供的服务包括为对听力音频替换声线方式,提供听力相关音频的播放服务，旨在提升学习兴趣，不提供培训教育服务。
        <br>
        本网站不对考试成绩，实际学习效果承担责任。
      </div>
      <div class="litte_title">
        2. 适用对象
      </div>
      <div class="content">
        服务适用于年满16岁的个人。访问或使用服务即表示您保证年满16岁。
      </div>

      <div class="litte_title">
        3. 账户注册
      </div>
      <div class="content">
        要使用某些服务功能，您可能需要创建账户。您有责任维护您的账户信息和密码的保密性，并对在您账户下发生的所有活动负责。您同意在发现您的账户或密码被未经授权的使用，或发生其他安全问题时立即通知本网站维护人员。您不得在任何时候使用其他人的账户或密码。
      </div>
      <div class="litte_title">
        4. 内容与知识产权
      </div>
      <div class="content">
        网站提供的音频播放内容均为免费,请勿对该内容进行商用性质传播，未经许可的二创二改，以及对音频内容进行恶意剪辑、造谣等违法行为。如因上述行为造成的任何侵权行为，本网站不承担任何责任。所有肖像，图像的版权均属于其相应明星本人,工作室及公司所有。本网站只提供web页面服务。<br><br>
        所有音频均为AI制作，音频内容均为虚构，请勿上升真人，以免对明星个人造成任何负面舆论。<br><br>
        我们无法对音频质量，答案准确性负责，如有错误，请于我们联系。
      </div>
      <div class="litte_title">
        5. 用户行为
      </div>

      <div class="content">
        5.1 法律法规禁止行为<br><br>

        不得从事包括但不限于以下任何行为，也不得为以下任何行为提供便利：<br><br>

        （1）反对宪法所确定的基本原则的；<br><br>

        （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br><br>

        （3）损害国家荣誉和利益的；<br><br>

        （4）煽动民族仇恨、民族歧视，破坏民族团结的；<br><br>
        （5）破坏国家宗教政策，宣扬邪教和封建迷信的；<br><br>

        （6）散布谣言，扰乱社会秩序，破坏社会稳定的；<br><br>

        （7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br><br>

        （8）宣传贩卖军火、毒品、违禁药品的；<br><br>

        （9）侮辱或者诽谤他人，侵害他人合法权益的；<br><br>

        （10）传播代孕、售卖个人信息、代开发票、办证刻章等不当行为的；<br><br>

        （11）含有法律、行政法规禁止的其他行为或内容的。<br><br>

        5.1 软件不当使用<br><br>
        干扰或破坏服务的运行，或用于提供服务的服务器或网络； 使用任何机器人、爬虫或其他自动或手动过程监视、复制或干扰服务或通过服务提供的任何内容。
      </div>
      <div class="litte_title">
        6. 修改服务及条款
      </div>
      <div class="content">
        本网站保留在不通知的情况下自行决定修改或中止服务或其任何部分的权利。 本网站还可能随时修改这些条款，修改后的条款将在网站上发布生效。继续使用服务表示您接受并同意遵守修改后的条款。您有责任定期查看这些条款以了解更改情况。
      </div>
    </div>
  </div>

</template>


<script>
export default {

}
</script>

<style scoped>
.content {
  margin: 10px 10px;
  color: rgb(6, 41, 47);
}

.text {
  width: 80%;
  margin: auto;
}

.litte_title {
  border-bottom: 2px solid #d3cfcf;
  padding: 10px 0px;
  font-weight: bold;
  font-size: 25px;
  margin-top: 20px;
}

.title {
  padding: 10px 0px;
  font-weight: bold;
  font-size: 30px;
  margin-top: 20px;
  border-bottom: 2px solid #d3cfcf;
}
</style>