<template>
  <div style="padding: 0 40px;">
    <div id="title" style="height: 10%"> 最 终 成 绩</div>
    <div id="grade">{{ score }}/{{ total }}</div>
    <div id="sheet">
      <div id="left-sheet">
        <div v-for="(value, key) in answerList">
          <!-- 如果是填空题 -->
          <div v-if="getType(key) == 2">
            <div v-for="answer in value">
              <div class="answerline"
                style="border-radius: 10px; padding: 5px; margin-bottom: 5px; border: 2px solid #000000; background-color: #ccccff; height:25px;">
                {{ answer }}
              </div>
            </div>

          </div>
          <div v-else>
            <div class="answerline"
              style="border-radius: 10px; padding: 5px; margin-bottom: 5px; border: 2px solid #000000; background-color: #ccccff; height:25px;">
              {{ convertFormat(value) }}
            </div>
          </div>
        </div>
      </div>
      <div id="right-sheet">
        <div v-for="(value, key) in answerToshow">
          <!-- 如果是填空题 -->
          <div v-if="getType(key) == 2">
            <div v-for="(answer, index) in value">
              <div class="answerline"
                style="border-radius: 10px; padding: 5px; margin-bottom: 5px; border: 2px solid #000000; background-color: #99ccff; height:25px;">
                <div v-show="errorDetail[key][index] == 0">
                  <p style="color:rgb(227, 54, 46) ;">
                    {{ answer }}
                  </p>
                </div>
                <div v-show="errorDetail[key][index] == 1">
                  <p>
                    {{ answer }}
                  </p>
                </div>
              </div>
            </div>

          </div>
          <!-- 如果是单选 -->

          <div v-if="getType(key) == 1">

            <div class="answerline"
              style="border-radius: 10px; padding: 5px; margin-bottom: 5px; border: 2px solid #000000; background-color: #99ccff; height:25px; ">


              <div v-show="errorDetail[key][0] == 0">
                <p style="color:rgb(227, 54, 46) ;">
                  {{ convertFormat(value) }}
                </p>
              </div>
              <div v-show="errorDetail[key][0] == 1">
                <p>
                  {{ convertFormat(value) }}
                </p>


              </div>

            </div>

          </div>

          <!-- 如果是多选 -->
          <div v-if="getType(key) == 0">

            <div class="answerline"
              style="border-radius: 10px; padding: 5px; margin-bottom: 5px; border: 2px solid #000000; background-color: #99ccff; height:25px; display: flex;justify-content:center; align-items:center">

              <div v-for="(answer, index) in value">
                <div>
                  <div v-show="errorDetail[key][index] == 0">
                    <div style="color:rgb(227, 54, 46);">{{ answer }}; </div>
                  </div>

                  <div v-show="errorDetail[key][index] == 1">
                    <div>{{ answer }}; </div>
                  </div>
                </div>
              </div>

            </div>



          </div>
        </div>

      </div>
    </div>

    <div>
      <el-button style="height: 40px;" color="rgba(167, 218, 245, 1)" @click="quit">
        <div class="choice">确定</div>
      </el-button>
      <el-button style="height: 40px;" color="rgba(167, 218, 245, 1)" @click="viewOriginalArticle">
        <div class="choice">原文精听与解析</div>
      </el-button>
    </div>


  </div>
</template>

<script>
import { useStore } from "vuex";
import { jwtDecode } from "jwt-decode";

const store = useStore();
export default {
  data() {
    return {
      score: 0,
      answerList: {},
      actualAnswer: {},
      errorDetail: {},
      total: 0,
      result: {},
      answerToshow: {}
    };
  },
  created() {
    this.answerList = {};
    this.actualAnswer = {};
    this.errorDetail = {};
    this.answerToshow = {};
  },
  mounted() {
    this.score = this.$route.query.score;
    this.total = this.$route.query.total;
    var answer = this.$store.state.answerList;
    var actual = this.$store.state.actualAnswer;
    var detail = this.$store.state.errorDetail;
    this.answerList = JSON.parse(JSON.stringify(answer));
    this.actualAnswer = JSON.parse(JSON.stringify(actual));
    this.errorDetail = JSON.parse(JSON.stringify(detail));

    // console.log(this.$store._state.data.answerList);
    Object.keys(this.answerList).forEach((key) => {
      this.answerToshow[key] = this.actualAnswer[key]
    });


  },
  methods: {

    getType(key) {
      if (key) {
        const regex = /(?:-)(\d+)/;
        var type = key.match(regex)[1];
        return type
      }
    },
    getKey(obj) {
      var key = Object.entries(obj)[0][0];
      return key
    },
    getValue(obj) {
      console.log('value1', obj[this.getKey(obj)])
      console.log('value', obj)

    },
    convertFormat(arr) {

      // console.log('item', arr)
      //  [ "B" ] 转 成B
      // [ "B","C"] 转成B;C
      return arr.join(';');
    },
    quit() {
      this.$router.push({ name: "kieltsView" });
    },
    viewOriginalArticle() {
      var token = localStorage.getItem("token")
      var decoded = jwtDecode(token);
      if (decoded.userType == 0) {
        this.$message.error('您的账户目前不支持该项功能哦,请选择右上角头像升级权益哦')
      } else {
        this.$router.push({
          name: "viewOriginalArticle",
          query: {
            paperId: this.$route.query.paperId,
            idolId: this.$route.query.idolId,
            score: this.score,
            total: this.total,
          },
        });
      }

    },
  },
};
</script>

<style scoped>
* {
  text-align: center;
  transition: all 0.3s;
  /* font-size: 40px; */
}

#title {
  width: 100%;
  height: 35px;
  font-size: 35px;
  font-family: "fangzheng";
  background-color: rgb(218, 228, 243);
}

#grade {
  font-family: "fangzheng";
  margin-top: 5px;
  height: 50px;
  font-size: 40px;
}

#sheet {
  display: flex;
  justify-content: center;
  text-align: center;
}

.choice {
  font-family: "fangzheng";
  font-size: 25px;
}

#left-sheet {
  font-size: 20px;
  font-family: "Arial";
  text-align: left;
  margin-right: 50px;

  width: 50%;
}

#right-sheet {
  font-size: 20px;
  font-family: "Arial";
  text-align: left;
  margin-left: 50px;

  width: 50%;
}
</style>